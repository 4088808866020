import { appTypes, appTypesProps } from '../../app';
import StepOneImage from '../../../../../../components/SvgIcons/setting';
import GreenTickSvg from '../../../../../../components/SvgIcons/greetick';
import RoundRdioSvg from '../../../../../../components/SvgIcons/roundRadio';
import CreateAppFooter from '../../footer';
import FromHeader from '../formHeader';
import { onFocusOut, onInputChange } from '../../../../../../components/lib/validation/createApp';
import ActiveGreen from 'components/SvgIcons/greetick/activeGreen';

interface Step1Props {
    formData: any;
    nextStep: () => void;
    prevStep: () => void;
    step: number;
    dispatchForm: any;
}

const Step = ({ formData, dispatchForm, nextStep, step, prevStep }: Step1Props) => {

    return (
        <div style={{ width: '100%' }}>
            <div
                style={{
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column'
                }}
            >
                <div className="main_card">
                    <FromHeader
                        title={"How Would You Like to Build Your App?"}
                        desc={"Choose from our ready-made templates for a quick and easy solution, or design a custom app tailored to your specific needs."} step={step} name={formData?.appType} />
                    <div style={{ margin: '64px 0px 0px' }} >
                        <div className="main_section_card"
                            style={{
                                display: 'flex',
                                flexWrap: 'wrap',
                                gap: 8,
                                margin: '20px 0px 40px',
                                cursor: 'pointer',
                                height: "100%",
                                overflow: "auto"

                            }}
                        >
                            <div
                                style={{
                                    width: '100%',
                                    gap: '20px',
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'space-between',
                                }}
                            >
                                {appTypes.map((item: appTypesProps, index: number) => (
                                    <div
                                        key={index}
                                        style={{
                                            width: '100%',
                                            display: 'flex',
                                            flexDirection: 'row',
                                            borderRadius: '12px', // Ensure rounded corners
                                            gap: '20px',
                                            border: '1px solid #929292',
                                            color: '#929292',
                                            position: 'relative', // Required for pseudo-elements
                                            ...(formData.fields.appType.value === item.title && {
                                                border: 'none', // Remove default border
                                                background: 'radial-gradient(50% 388.06% at 50% 50%, #0054B5 0%, #01B7FF 100%)',
                                                padding: '2px', // Create space for the pseudo border
                                                borderRadius: '12px',
                                            })
                                        }}
                                    >
                                        <div
                                            style={{
                                                background: '#062838', // Inner background to maintain structure
                                                borderRadius: '10px',
                                                width: '100%',
                                                display: 'flex',
                                                flexDirection: 'column',
                                                padding: '25px',
                                            }}
                                        >
                                            <label style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%', justifyContent: 'start',  cursor: 'pointer' , padding: '0px' }}>
                                                {/* Custom SVG for Radio Button */}
                                                <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between', alignItems: 'center' , marginBottom:'15px' }}>
                                                    <StepOneImage />
                                                    <div className="radio-svg">
                                                        {formData.fields.appType.value === item.title ? (
                                                            // <GreenTickSvg />
                                                            <ActiveGreen />
                                                        ) : (
                                                            <RoundRdioSvg />
                                                        )}
                                                    </div>
                                                </div>
                                                <input
                                                    type="radio"
                                                    name="appType"
                                                    value={item.title}
                                                    checked={formData.fields.appType.value === item.title}
                                                    onChange={e => {
                                                        onInputChange("appType", e.target.value, dispatchForm, formData);
                                                    }}
                                                    onBlur={e => {
                                                        onFocusOut("appType", e.target.value, dispatchForm, formData);
                                                    }}
                                                    style={{ display: 'none' }} // Hide the default radio button
                                                />
                                                {/* Text Content */}
                                                <div style={{ display: 'flex', flexDirection: 'column', width: '100%', justifyContent: 'space-between' }}>
                                                    <div style={{ fontWeight: '700', fontSize: '20px', color: '#EDEDED', lineHeight: '35.14px', fontFamily: "'Mulish', sans-serif" , marginBottom: '10px' }} >{item.title}</div>
                                                    <div style={{ fontWeight: '600', fontSize: '18px', lineHeight: '35.14px', color: '#bfbfbf', fontFamily: "'Mulish', sans-serif" }}>{item.desc}</div>
                                                </div>
                                            </label>
                                        </div>
                                    </div>
                                ))}
                            </div>
                            {formData.fields.appType.touched && formData.fields.appType.hasError && (
                                <div className="error">{formData.fields.appType.error}</div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <CreateAppFooter nextStep={nextStep} step={step} prevStep={prevStep} price={"0"} type={0} setActiveTab={0} />
        </div >
    )
};
export default Step;