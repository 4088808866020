import React, { useState } from 'react';

interface Props {
    image: string;
    job?: { appTitle: string };
    PlaceholderImage: string; // Pass the SVG path or component
}

const ImageWithFallback: React.FC<Props> = ({ image, job, PlaceholderImage }) => {
    const [hasError, setHasError] = useState(false);

    return (
        <div style={{ width: '204px', height: '236px' }}>
            {hasError ? (
                <img 
                    src={PlaceholderImage} 
                    alt="Placeholder" 
                    width="204" 
                    height="236" 
                    style={{ objectFit: 'contain' }} 
                />
            ) : (
                <img
                    width="204"
                    height="236"
                    src={image}
                    style={{ objectFit: 'contain' }}
                    alt={job?.appTitle}
                    onError={() => setHasError(true)}
                />
            )}
        </div>
    );
};

export default ImageWithFallback;
