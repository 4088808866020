interface Props {
  width?: string | number;
  height?: string | number;
}

const PaymentABillingIcon = ({ height = "22px", width = "22px" }: Props) => {
  return (
    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M11 20.1667C16.0626 20.1667 20.1666 16.0626 20.1666 11C20.1666 5.9374 16.0626 1.83334 11 1.83334C5.93737 1.83334 1.83331 5.9374 1.83331 11C1.83331 16.0626 5.93737 20.1667 11 20.1667Z" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M14.6666 7.33334H9.16665C8.68042 7.33334 8.2141 7.5265 7.87028 7.87031C7.52647 8.21413 7.33331 8.68045 7.33331 9.16668C7.33331 9.65291 7.52647 10.1192 7.87028 10.463C8.2141 10.8069 8.68042 11 9.16665 11H12.8333C13.3195 11 13.7859 11.1932 14.1297 11.537C14.4735 11.8808 14.6666 12.3471 14.6666 12.8333C14.6666 13.3196 14.4735 13.7859 14.1297 14.1297C13.7859 14.4735 13.3195 14.6667 12.8333 14.6667H7.33331" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M11 16.5V5.5" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  )
}
export default PaymentABillingIcon;