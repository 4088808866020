const MoonIcon = () => {
    return (
        <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M7 4.5C7 9.47056 11.0294 13.5 16 13.5C16.9093 13.5 17.787 13.3655 18.6144 13.1147C17.4943 16.8103 14.0613 19.4999 10 19.4999C5.02944 19.4999 1 15.4707 1 10.5001C1 6.43883 3.69007 3.00583 7.38561 1.88574C7.13484 2.71311 7 3.59074 7 4.5Z" stroke="url(#paint0_radial_33439_38)" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <defs>
        <radialGradient id="paint0_radial_33439_38" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(9.8072 10.6928) scale(8.8072 68.3533)">
        <stop stopColor="#0054B5"/>
        <stop offset="1" stopColor="#01B7FF"/>
        </radialGradient>
        </defs>
        </svg>
        

    )
}
export default MoonIcon;