import { useEffect } from 'react';
import CreateAppFooter from 'pages/customer/CustomerPortal/CreateApp/footer';
import FromHeader from 'pages/customer/CustomerPortal/CreateApp/Step/formHeader';
import './index.sass';
import OrderSummery from 'components/common/orderSummery';
import AddCard from 'components/common/paymentCard/add';
import PaymentsCard from 'components/common/paymentCard';
import PaymentStatus from 'components/common/statusPopup';

interface Step1Props {
    formData?: any;
    submitForm?: any;
    prevStep?: any;
    step?: number;
    name?: string;
    activeTab?:any;
    setActiveTab?:any;
    type?:number;
    patymentDone?:boolean;
    setSelectedPaymentCard?:any;
    dispatchForm?: any;
    tabs?:any;
    paymentType?:string;
    title?:any;
    desc?:any;
    descPop?:any;
    titlePop?:any;
    setUpdatePaymentSubmit?:any;
    preview?:any;
}

const PatmentTabCard = ({ formData,setActiveTab,activeTab, submitForm, prevStep, step, name,patymentDone,setSelectedPaymentCard,tabs,paymentType,title,desc,descPop,titlePop,setUpdatePaymentSubmit,preview }: Step1Props) => {

    const handleTabClick = (index: number) => {
        if(index === 2) return;
        setActiveTab(index);
        index !== activeTab && setUpdatePaymentSubmit && setUpdatePaymentSubmit(false);
    };

    useEffect(() => {
       if(patymentDone){
        setActiveTab(2);
       }
    }, [patymentDone]);


    return (
        <div style={{ height: '100%' }}>
                <div className="main_card" style={{ height: '100%' }}>
                    <FromHeader
                        title={tabs[activeTab]}
                        desc={descPop[activeTab]}
                        step={step}
                        name={name}
                    />
                    <div className="tab-container" style={{
                        display: 'flex',
                        flexDirection: 'column',
                        height: '73%'
                    }}>
                        <div className="tab-group" >
                            {tabs && tabs.map((tab:any, index:any) => (
                                <div
                                    key={index}
                                    className={`tab ${activeTab === index ? 'active' : ''}`}
                                    onClick={() => handleTabClick(index)}
                                    aria-selected={activeTab === index}
                                    style={{ 
                                        background: activeTab === index ? '#222647' : '#fff',
                                      
                                     }} // Apply color here
                                >
                                    <span className="tab-label">{tab}</span>
                                </div>
                            ))}
                        </div>
              
                        <div className="tab-content" style={{ height: '100%', overflowY: 'auto' }}>
                            {activeTab === 0 && <OrderSummery formData={formData} preview={preview} />}
                            {activeTab === 1 ? <PaymentsCard setActiveTab={setActiveTab} setSelectedPaymentCard={setSelectedPaymentCard} /> : activeTab === 3 && <AddCard stepType={activeTab} setActiveTab={setActiveTab} setSelectedPaymentCard={setSelectedPaymentCard} />}
                            {activeTab === 2 && <PaymentStatus title={title} desc={desc} />}
                        </div>
                    </div>
                </div>
                { paymentType === "deposit" && step && <CreateAppFooter nextStep={submitForm} prevStep={prevStep} step={step} price={formData?.price} type={activeTab} setActiveTab={setActiveTab}/>}
            </div>
        
    );
};

export default PatmentTabCard;