interface Props {
    color?: any;
    classstyle?: any;
}

const GreenTickSvg = ({ color,classstyle }: Props) => {

    const isGradient = classstyle === "gradient";

    return (
        <svg 
        width="22" 
        height="22" 
        viewBox="0 0 22 22" 
        fill="none" 
        xmlns="http://www.w3.org/2000/svg"
    >
        {/* Define gradient */}
        {isGradient && (
            <defs>
                <radialGradient id="gradientFill" cx="50%" cy="50%" r="50%">
                    <stop offset="0%" stopColor="rgba(0, 84, 181, 1)" />
                    <stop offset="100%" stopColor="rgba(1, 183, 255, 1)" />
                </radialGradient>
            </defs>
        )}

        {/* Circle with gradient fill */}
        <circle 
            cx="11" 
            cy="11" 
            r="11" 
            fill={ color && "" || ""} 
        />

        {/* Path with gradient stroke */}
        <path 
            d="M5.75 11.75L9.25 15.25L16.25 7.75" 
            stroke={isGradient ? "url(#gradientFill)" : color || "white"} 
            strokeWidth="1.5" 
            strokeLinecap="round" 
            strokeLinejoin="round" 
        />
    </svg>
    )
}
export default GreenTickSvg;
