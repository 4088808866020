interface Props {
  width?: string | number;
  height?: string | number;
}

const DashboardIcon = ({ height = "22px", width = "22px" }: Props) => {
  return (
    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M8.25 2.75H3.66667C3.16041 2.75 2.75 3.16041 2.75 3.66667V10.0833C2.75 10.5896 3.16041 11 3.66667 11H8.25C8.75626 11 9.16667 10.5896 9.16667 10.0833V3.66667C9.16667 3.16041 8.75626 2.75 8.25 2.75Z" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M18.3333 2.75H13.75C13.2437 2.75 12.8333 3.16041 12.8333 3.66667V6.41667C12.8333 6.92293 13.2437 7.33333 13.75 7.33333H18.3333C18.8396 7.33333 19.25 6.92293 19.25 6.41667V3.66667C19.25 3.16041 18.8396 2.75 18.3333 2.75Z" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M18.3333 11H13.75C13.2437 11 12.8333 11.4104 12.8333 11.9167V18.3333C12.8333 18.8396 13.2437 19.25 13.75 19.25H18.3333C18.8396 19.25 19.25 18.8396 19.25 18.3333V11.9167C19.25 11.4104 18.8396 11 18.3333 11Z" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M8.25 14.6667H3.66667C3.16041 14.6667 2.75 15.0771 2.75 15.5833V18.3333C2.75 18.8396 3.16041 19.25 3.66667 19.25H8.25C8.75626 19.25 9.16667 18.8396 9.16667 18.3333V15.5833C9.16667 15.0771 8.75626 14.6667 8.25 14.6667Z" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  )
}
export default DashboardIcon;