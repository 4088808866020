const UparrowIcon = () => {
    return (
        <svg style={{
            position: 'absolute',
            right: '78px',
            top: '-45px'
        }} width="16" height="8" viewBox="0 0 16 8" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M16 8L9.41421 1.41421C8.63316 0.633163 7.36684 0.633165 6.58579 1.41421L0 8H16Z" fill="#FEFEFE" />
        </svg>
    )
}
export default UparrowIcon;