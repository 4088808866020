import React from "react";

const StepOneImage: React.FC = () => {
    return (
        <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M20.9825 38.3405C20.473 38.1768 20.0153 37.8825 19.6551 37.4869C19.2948 37.0912 19.0445 36.608 18.9292 36.0855C18.812 35.6019 18.5823 35.1527 18.2587 34.7747C17.9351 34.3966 17.5269 34.1003 17.0671 33.9098C16.6074 33.7193 16.1092 33.6401 15.6131 33.6786C15.1169 33.717 14.6369 33.8721 14.212 34.1312C11.3832 35.8545 8.14367 32.6168 9.867 29.7862C10.1257 29.3615 10.2805 28.8817 10.3189 28.3859C10.3573 27.8901 10.2781 27.3923 10.0879 26.9328C9.89762 26.4734 9.60164 26.0653 9.224 25.7417C8.84636 25.4182 8.3977 25.1883 7.9145 25.0708C4.69517 24.2898 4.69517 19.7102 7.9145 18.9292C8.39813 18.812 8.84726 18.5823 9.22533 18.2587C9.6034 17.9351 9.89973 17.5269 10.0902 17.0671C10.2807 16.6074 10.3599 16.1092 10.3214 15.6131C10.283 15.1169 10.1279 14.6369 9.86883 14.212C8.1455 11.3832 11.3832 8.14367 14.2138 9.867C16.0472 10.9817 18.4232 9.99533 18.9292 7.9145C19.7102 4.69517 24.2898 4.69517 25.0708 7.9145C25.188 8.39813 25.4177 8.84726 25.7413 9.22533C26.0649 9.6034 26.4731 9.89973 26.9329 10.0902C27.3926 10.2807 27.8908 10.3599 28.3869 10.3214C28.8831 10.283 29.3631 10.1279 29.788 9.86883C32.6168 8.1455 35.8563 11.3832 34.133 14.2138C33.8743 14.6385 33.7195 15.1183 33.6811 15.6141C33.6427 16.1099 33.7219 16.6077 33.9121 17.0672C34.1024 17.5266 34.3984 17.9347 34.776 18.2583C35.1536 18.5818 35.6023 18.8117 36.0855 18.9292C38.4872 19.5122 39.0977 22.2108 37.9133 23.8682M27.5 34.8333L31.1667 38.5L38.5 31.1667" stroke="url(#paint0_radial_31859_826)" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M16.5 22C16.5 23.4587 17.0795 24.8576 18.1109 25.8891C19.1424 26.9205 20.5413 27.5 22 27.5C23.4587 27.5 24.8576 26.9205 25.8891 25.8891C26.9205 24.8576 27.5 23.4587 27.5 22C27.5 20.5413 26.9205 19.1424 25.8891 18.1109C24.8576 17.0795 23.4587 16.5 22 16.5C20.5413 16.5 19.1424 17.0795 18.1109 18.1109C17.0795 19.1424 16.5 20.5413 16.5 22Z" stroke="url(#paint1_radial_31859_826)" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            <defs>
                <radialGradient id="paint0_radial_31859_826" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(22 22) scale(16.5 128.06)">
                    <stop stopColor="#0054B5" />
                    <stop offset="1" stopColor="#01B7FF" />
                </radialGradient>
                <radialGradient id="paint1_radial_31859_826" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(22 22) scale(5.5 42.6866)">
                    <stop stopColor="#0054B5" />
                    <stop offset="1" stopColor="#01B7FF" />
                </radialGradient>
            </defs>
        </svg>

    )
}
export default StepOneImage;