import { useContext, useState } from "react";
import KebabMenu from "components/KebabMenu/KebabMenu";
import CalenderIcon from "components/SvgIcons/calenderIcon";
import PlaceholderImage from "assets/Frame_all.svg";
import BadgeButton from "../badges";
import { Button } from 'antd';
import { useDispatch } from 'react-redux';
import { setAppJob } from 'store/slices/createAppSlice';
import ImageWithFallback from "../ImageWithFallback";
import { toast } from 'react-toastify';
import { AuthContext } from "context/AuthContext";
import { getUrlFileName,checkFileName } from 'pages/customer/CustomerPortal/utils';

interface Props {
    kebabMenuItems?: any;
    job: any;
    image: string;
    setSelectedTemp?: any;
    setShowPerview?: any;
    setShowAppDemo?: any;
    setShowAppDemoJob?: any;
    setShowSubscriptionPage?: any;
}

const NewJobCard = ({ kebabMenuItems, job, image, setSelectedTemp, setShowPerview, setShowAppDemo, setShowSubscriptionPage }: Props) => {
    const dispatch = useDispatch();
    const auth = useContext(AuthContext);
    const [appForAttachment, setAppForAttachment] = useState<string | null>(null);
    const updateJob = (job: any) => {
        dispatch(setAppJob(job));
    };

    // Function to format date
    const formatDate = (dateString: any) => {
        const date = new Date(dateString);
        const day = date.getDate().toString().padStart(2, "0");
        const month = (date.getMonth() + 1).toString().padStart(2, "0"); // months are zero-based
        const year = date.getFullYear();
        return `${day}-${month}-${year}`;
    };

    const handleOpenTemplate = (id: string, name: string) => {
        const data = id ? id : name ?? "";
        setSelectedTemp(data);
        setShowPerview(true);
    }

    const handleOpenAppDemo = (job: string) => {
        updateJob(job);
        setShowAppDemo(true);
    }

    const handleOpenSubscription = () => {
        updateJob(job);
        setShowSubscriptionPage(true);
    }
    /** download zip file  */
    const handleDownloadApp = async (userId: any, jobId: any) => {
        if (!userId || !jobId) {
            toast.error("Error: Missing user ID or job ID!");
            return;
        }
    
        const fileName = `user_${userId}_job_${jobId}.zip`;

        try {
            // Fetch file metadata
            const attachmentURL = await getUrlFileName(fileName);
            const cleanUrl = new URL(attachmentURL);
            cleanUrl.search = ""; 
            const fileNameNew = cleanUrl.toString();

            if (!fileNameNew) {
                toast.error(`File not found.`);
                return;
            }
   
            const checkFile = await checkFileName(fileNameNew);
            if (!checkFile) {
                toast.error(`File not found.`);
                return;
            }

            // Proceed with download if file is readable
            const link = document.createElement("a");
            link.href = attachmentURL;
            link.setAttribute("download", fileNameNew);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
    
            toast.success("Download started successfully!");
        } catch (error: any) {
            console.error("Download error:", error);
            toast.error(`Download failed: ${error.message || "Unknown error"}`);
        }
    };
    
    const redirectToURL = (url: string) => {
        window.open(url, "_blank");
    };
    
    const buttonStyle = {
        // width: '100%',
        background: 'radial-gradient(circle, rgb(0, 84, 181) 50%, rgb(1, 183, 255) 100%) text',
        backgroundClip: 'text',
        color: 'transparent',  // Make the text color transparent so the gradient shows
        padding: '10px 20px',
        WebkitBackgroundClip: 'text',  // Ensure cross-browser support for text gradient
    };

    return (
        <div className='new-job' style={{
            width: '100%',
            height: 'auto',
            padding: '20px',
            border: '1px solid #BFBFBF',
            display: 'flex',
            borderRadius: '4px',
            justifyContent: 'space-between',
            gap: "1rem"
        }}>
            <div className='' style={{
                width: '25%'
            }}>
                <ImageWithFallback image={image} job={job} PlaceholderImage={PlaceholderImage} />
            </div>
            <div className='' style={{
                width: '70%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                gap: 15,
            }}>
                <div style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    gap: "15px"
                }}>
                    <h4 style={{
                        color: '#fff',
                    }}>{job?.appTitle}</h4>
                    <BadgeButton status={job?.jobStatus} />
                </div>
                <div className='job_middle_content' style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 15
                }}>
                    <p style={{
                        display: 'flex',
                        justifyContent: 'flex-start', // Corrected 'justifyItems' to 'justifyContent'
                        alignItems: 'center',
                        color: '#BFBFBF',
                        gap: '20px' // Ensure gap is in 'px'
                    }}> <CalenderIcon /> Last Update: {job?.updatedAt && formatDate(job?.updatedAt)}
                    </p>
                    <p className='description' style={{
                        color: '#929292'
                    }}>{job?.appDescription}</p>
                    <p className='description' style={{
                        color: '#929292'
                    }}>{job?.website}</p>
                </div>
                <div className='job_middle_content' style={{
                    display: 'flex',
                    marginTop: '10px',
                    gap: 10,
                    width: '100%',
                }}>
                    {
                        (job?.interimPaymentReceived === true && job?.finalPaymentReceived === false) || job?.finalPaymentReceived === true ?
                            <Button className="newLight" style={buttonStyle} onClick={() => handleOpenSubscription()} >Our Subscription Plans</Button>
                            :
                            <Button className="newLight" style={buttonStyle} onClick={() => handleOpenTemplate(job?.appThemeId, job?.appTheme)} >Browse Template</Button>
                    }
                    {
                        job?.finalPaymentReceived === true ?
                            !job?.website ?
                                <Button className="newDark" style={{
                                    width: '100%'
                                }} onClick={() => handleDownloadApp(auth?.user?._id, job?.id)} >Download App</Button>
                                :
                                <Button className="newDark" style={{
                                    width: '100%'
                                }} onClick={() => redirectToURL(job?.website)} >view app</Button>
                            :
                            <Button className="newDark" style={{
                                width: '100%'
                            }} onClick={() => handleOpenAppDemo(job)} > {job?.interimPaymentReceived === true && job?.finalPaymentReceived === false ? 'Final Payment' : 'App Demo'}</Button>
                    }

                </div>
            </div>
            <div className='' style={{
                width: '5%'
            }}>
                <div className='new-job-right-inner'>
                    <KebabMenu items={kebabMenuItems} style={{
                        marginTop: '-5px',
                        color: '#EDEDED'
                    }} />
                </div>
            </div>
        </div>
    )
}
export default NewJobCard;