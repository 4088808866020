interface Props {
  width?: string | number;
  height?: string | number;
}

const ProtonProIcon = ({ height = "22px", width = "22px" }: Props) => {
  return (
    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M8.17146 15.3519C8.17919 14.0201 8.18306 12.6883 8.1908 11.3526C8.1908 11.3246 8.19854 11.205 8.29137 11.1053C8.39968 10.9897 8.54281 10.9897 8.56601 10.9937C10.2526 10.9857 11.9391 10.9777 13.6218 10.9738C14.3799 10.7664 14.8828 10.0327 14.8248 9.26318C14.7706 8.56938 14.2677 7.97527 13.5831 7.81178C11.7882 7.81976 9.99725 7.82773 8.2024 7.83969C8.02833 7.80381 7.91229 7.64431 7.92389 7.47286C7.9355 7.31735 8.05541 7.18577 8.21014 7.15786C10.1056 7.15786 11.9971 7.15387 13.8925 7.15387C14.8751 7.42501 15.5752 8.32615 15.6139 9.35489C15.6526 10.4395 14.9486 11.4283 13.9157 11.7274C12.2369 11.7274 10.5581 11.7274 8.87934 11.7274C8.87934 12.9236 8.87934 14.1198 8.87934 15.316C8.83679 15.4994 8.67819 15.623 8.50799 15.615C8.35713 15.6031 8.22561 15.4994 8.17146 15.3519Z" fill="currentColor" />
      <path d="M11 21C16.5228 21 21 16.5228 21 11C21 5.47715 16.5228 1 11 1C5.47715 1 1 5.47715 1 11C1 16.5228 5.47715 21 11 21Z" stroke="currentColor" strokeMiterlimit="10" />
      <path d="M11 18.6923C15.2483 18.6923 18.6923 15.2483 18.6923 11C18.6923 6.75164 15.2483 3.30768 11 3.30768C6.75164 3.30768 3.30768 6.75164 3.30768 11C3.30768 15.2483 6.75164 18.6923 11 18.6923Z" stroke="currentColor" strokeMiterlimit="10" />
    </svg>
  )
}
export default ProtonProIcon;