import CircleCheckSvg from "components/SvgIcons/circleCheck";

interface Props {
    title?: string;
    desc?: string;
    type?: string;
}

const StatusPopup = ({ title, desc, type }: Props) => {
    const containerStyle: any = {
        height: "338px",
        marginTop: "25px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
};

return (
    <div style={containerStyle}>
        <div style={{
            display: 'flex',
            height: '30vh',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            background: !type ? '#2226470D' : 'transparent'
        }}>
            <div style={{
                display: 'flex',
                maxWidth: '586px',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                gap: '10px'
            }}>
                <CircleCheckSvg />
                <p style={{
                    fontSize: '20px',
                    fontWeight: '500'
                }}>{title}</p>
                <p style={{
                    fontSize: '16px',
                    color: '#929292',
                    textAlign: 'center'
                }}>{desc}</p>
            </div>
        </div>
    </div>
)
}
export default StatusPopup;