import { HTMLAttributes, useCallback, useState } from 'react'

import chevronDownIcon from 'assets/chevron-down.svg'
import CogIcon from 'assets/cog.svg'
import logoutIcon from 'assets/log-out.svg'
import { USER_ROLES } from 'utils/constants'
import { logout } from 'utils/helpers'
import arrowsdrop from 'assets/drop.png';

import Avatar from 'components/Avatar/Avatar'

import './user-menu.sass'
import { useNavigate } from 'react-router-dom'
import { LINKS } from 'routes/constants'

interface IUserMenuProps extends HTMLAttributes<HTMLDivElement> {
  name?: string
  role?: string
  avatar?: string
}

const UserMenu = ({ name, role, avatar, ...props }: IUserMenuProps) => {
  const [menuOpen, setMenuOpen] = useState<boolean>(false)
  const navigate = useNavigate();
  const handleUserMenuClick = useCallback(() => {
    setMenuOpen(!menuOpen)
  }, [menuOpen, setMenuOpen])

  if (!(name || role)) return null



  return (
    <div className="user-menu" {...props}>
      <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }} onClick={handleUserMenuClick} >
        <Avatar name={name} url={avatar} size={36} />
        <div className="details">
          <div className="name">
            {/* <span>{name}</span> */}
            <img src={chevronDownIcon} alt="" />
          </div>
          {/* {
          // @ts-ignore
          USER_ROLES[role] && <div className="role">{USER_ROLES[role]}</div>
        } */}
        </div>
      </div>
      {menuOpen && (
        <div className="options-menu">
          <div className='dropdownTri'>
            <img src={arrowsdrop} alt="arrows" className='arrowstep' />
          </div>
          <ul style={{ overflow: 'hidden', color: 'black' }}>
            <li onClick={() => {
              setMenuOpen(false);
              navigate(LINKS.EDITPROFILE)
            }}
            >
              <img src={CogIcon} alt="" />
              Settings
            </li>
            <li onClick={logout}>
              <img src={logoutIcon} alt="" />
              Logout
            </li>
          </ul>
        </div>
      )}
    </div>
  )
}

export default UserMenu
