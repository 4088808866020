import { useState, useContext, Dispatch, useReducer, useEffect } from 'react'
import Step from './Step/step';
import Step1 from './Step/one';
import Step2 from './Step/two';
import Step3 from './Step/three';
import Step4 from './Step/four';
import axios, { AxiosRequestConfig } from 'axios';
import { URL_PATH } from 'utils/constants';
import { AuthContext } from 'context/AuthContext'
import { toast } from 'react-toastify';
import { UPDATE_FORM, formReducer, validateInput, initialState, onInputChange } from '../../../../components/lib/validation/createApp';
import { createNotification, NotiTypeEnum, Notification } from '../utils';
import { useDispatch } from 'react-redux';
import { addNotification } from 'store/slices/notifactionSlice';

interface PaymentRequest {
    user_Id: any;
    customerId: any;
    paymentMethodId?: string;
    amount?: any;
    currency: string;
    description: string;
    jobId: string;
    paymentType?: string;
}

const confirmValidity = (formState: any, dispatch: Dispatch<any>) => {
    let isFormValid = true;

    for (let name in formState) {
        const item = formState[name];
        const { value } = item;
        const { hasError, error } = validateInput(name, value, formState, formState.currentStep);

        if (hasError) {
            isFormValid = false;
        }

        dispatch({
            type: UPDATE_FORM,
            data: {
                name,
                value,
                hasError,
                error,
                touched: true,
                isFormValid, // Update the overall form validity
            },
        });
    }

    return isFormValid;
};

const MultiStepForm: React.FC = () => {
    const [activeTab, setActiveTab] = useState<number>(0);
    const auth = useContext(AuthContext);
    const [step, setStep] = useState(0);
    const [hasValidated, setHasValidated] = useState(false);
    const [patymentDone, setPatymentDone] = useState(false);
    const [selectedPaymentCard, setSelectedPaymentCard] = useState<any>(null)
    const [formData, dispatchForm] = useReducer(formReducer, initialState);
    const [preview, setPreview] = useState<string | null>(null);
    const [appNameNoti, setAppNameNoti] = useState('');
    const [appImageNoti, setAppImageNoti] = useState('');
    const dispatch = useDispatch();

 
    const nextStep = () => {
        setHasValidated(false);
        if (!hasValidated) {
            // Perform validation only if not validated yet
            const { fields } = formData;
            let isValid = true;

            // Validate fields specific to the current step
            Object.keys(fields).forEach((field) => {
                const { hasError, error } = validateInput(field, fields[field].value, formData.fields, step);

                if (hasError) {
                    isValid = false; // Set isValid to false if any field has error

                    dispatchForm({
                        type: UPDATE_FORM,
                        data: {
                            name: field,
                            hasError,
                            touched: true,
                            value: '', // Clear the value on error if needed
                            error, // Show the specific error message
                            isFormValid: false,
                        },
                    });
                }
            });

            if (!isValid) {
                // Show a toast error message if validation fails
                //toast.error('Please correct the highlighted fields.');
            } else {
                setHasValidated(true); // Set validation flag if all fields are valid
                setStep(step + 1); // Move to the next step
            }
        } else {
            setStep(step + 1); // If already validated, move to the next step
        }
        setHasValidated(false);
    };


    const prevStep = () => setStep(step - 1);

    /** Step of process
     * create blank app
     * update app data as inactive app
     * make payment for app
     * update app status as app active
     * payment sucess page
     */

    /** create  payment for app  */
    const paymentHandler = async (deposit: any, paymentType: any, id: any, jobName: any) => {
        const paymentRequest: PaymentRequest = {
            user_Id: auth?.user?._id,
            customerId: auth?.user?.stripeCustomerId,
            paymentMethodId: selectedPaymentCard.id,
            amount: deposit * 100,
            currency: "usd",
            description: "Your payment description here",
            jobId: id,
            paymentType: paymentType,
        };

        const configE: AxiosRequestConfig = {
            method: 'post',
            maxBodyLength: Infinity,
            url: `${URL_PATH.CLIENT1}create-stripe-payment`,
            headers: {
                'Content-Type': 'application/json',
            },
            data: JSON.stringify(paymentRequest),
        };

        try {
            const apiResult: any = await axios.request(configE);
            //console.log(apiResult);
            if (apiResult?.data.success) {
                // If payment successful, activate app and update payment received
                const jobobj = {
                    isJobActive: true,
                    depositReceived: true,
                    jobStatus: "in_progress",
                    updatedAt: new Date()
                };
                handelUpdateApp(jobobj, id, 'complete');
                setActiveTab(0);
                let notificationData: Notification = {
                    appid: id,
                    image:appImageNoti,
                    user_id: auth?.user?._id,
                    type: NotiTypeEnum?.PAYMENT,
                    message: `${appNameNoti} App first payment is successful done.`,
                    is_read: false,
                };

                try {
                    // Create notification first
                    await createNotification(notificationData);
                    dispatch(addNotification(notificationData));
                } catch (error: any) {
                    throw new Error(`Error during notification creation or app update: ${error?.message}`);
                }
            }

            if (apiResult.data.status === 500) {
                toast.error(apiResult?.data.message);
            }

        } catch (err: any) {
            // Log the error for debugging
            console.error("PaymentHandler Error:", err);

            // Handle known specific error scenarios
            if (axios.isAxiosError(err)) {
                // If the error is an Axios error, check for status codes
                if (err.response?.status === 500) {
                    toast.error("Internal server error. Please try again later.");
                } else if (err.response?.status === 401) {
                    toast.error("Unauthorized. Please check your authentication.");
                } else {
                    toast.error(err?.response?.data?.message || 'An unexpected error occurred.');
                }
            } else {
                toast.error('An unexpected error occurred.');
            }
        }
    };


    const handelFormSubmit = async (payload: any) => {
        const config: AxiosRequestConfig = {
            method: 'post',
            maxBodyLength: Infinity,
            url: `${URL_PATH.CLIENT1}JobCreate`,
            headers: {
                'Content-Type': 'application/json',
            },
            data: JSON.stringify({
                name: "",
                customer_name: auth?.user?.name,
                customer_email: auth?.user?.email,
                invite_email: ``,
                isSubmitted: false,
                isJobActive: false,
                createdAt: new Date(),
            }),
        };

        try {
            const jobCreate: any = await axios.request(config);
            const id = jobCreate?.data?.data?._id;

            /** update App data */
            handelUpdateApp(payload, id, 'new');

        } catch (error: any) {
            toast.error(error?.message, {
                position: toast.POSITION.BOTTOM_CENTER,
            })
        }
    }


    /** create job but not active */
    const submitForm = async () => {
        const formVaild = confirmValidity(formData, dispatchForm);

        if (!formVaild) {
            toast.error(' Please fill in all required fields.', {
                position: toast.POSITION.BOTTOM_CENTER,
            })
        }

        const payload = {
            appTitle: formData?.fields.name.value || "",
            appIndustry: formData?.fields.appType.value || "",
            appImage: formData?.fields.attachment.value || null,
            attachments: [],
            appPrice: formData?.fields.price.value || 0,
            appTheme: formData?.fields.template.value || "",
            appThemeId: formData?.fields.template_id.value || "",
            website: formData?.fields.website.value || "",
            isSubmitted: true,
            depositReceived: false,
            finalPaymentReceived: false,
            buildingFinished: false,
            appDescription: formData?.fields.message?.value,
            isJobActive: false,
            deposit: formData?.fields.price.value ? parseInt(formData.fields.price.value, 10) : 0,
            finalPayment: 0,
            interimPayment: 0,
            interimPaymentReceived: false,
            updatedAt: new Date()
        }
        setAppNameNoti(formData?.fields.name.value);
        setAppImageNoti(formData?.fields.attachment.value);
        handelFormSubmit(payload);
    };

    /** update job function */
    const handelUpdateApp = async (payload: any, id: string, type: any) => {
        const config1: AxiosRequestConfig = {
            method: 'patch',
            maxBodyLength: Infinity,
            url: `${URL_PATH.CLIENT1}customerJobUpdate/${id}`,
            headers: {
                'Content-Type': 'application/json',
            },
            data: payload,
        };

        try {
            const updateReq: any = await axios.request(config1);

            if (updateReq?.data?.status === 200) {
                //Handle payment logic
                if (type === "new") {
                    const deposit = payload?.deposit;
                    const paymentType = "deposit";
                    paymentHandler(deposit, paymentType, id, payload?.appTitle);
                }

                if (type === "complete") {
                    setPatymentDone(true);
                    setActiveTab(2);

                    const notificationData: Notification = {
                        appid: id,
                        image:appImageNoti,
                        user_id: auth?.user?._id,
                        type: NotiTypeEnum?.APP,
                        message: `${appNameNoti} App is ready`,
                        is_read: false,
                    };

                    try {
                        // Create notification first
                        await createNotification(notificationData);
                        dispatch(addNotification(notificationData));
                    } catch (error: any) {
                        throw new Error(`Error during notification creation or app update: ${error?.message}`);
                    }
                }


            } else {
                toast.error(updateReq?.data?.message, {
                    position: toast.POSITION.BOTTOM_CENTER,
                });
            }

        } catch (error: any) {
            // Provide a more meaningful error message to the user
            const errorMessage = error?.response?.data?.message || error?.message || "An unexpected error occurred.";
            toast.error(errorMessage, {
                position: toast.POSITION.BOTTOM_CENTER,
            });
        }
    };

    return (
        <div className="stepsWrapperDiv">
            {step === 0 && (
                <Step formData={formData} nextStep={nextStep} step={step} prevStep={prevStep} dispatchForm={dispatchForm} />
            )}
            {step === 1 && (
                <Step1 formData={formData} nextStep={nextStep} step={step} name={formData?.fields?.appType?.value} prevStep={prevStep} dispatchForm={dispatchForm} />
            )}
            {step === 2 && (
                <Step2 formData={formData} nextStep={nextStep} step={step} name={formData?.fields?.appType?.value} prevStep={prevStep} dispatchForm={dispatchForm} />
            )}
            {step === 3 && (
                <Step3 formData={formData} nextStep={nextStep} step={step} name={formData?.fields?.appType?.value} prevStep={prevStep} dispatchForm={dispatchForm} preview={preview} setPreview={setPreview} />
            )}
            {step === 4 && (
                <Step4 formData={formData} submitForm={submitForm} step={step} name={formData?.fields?.appType?.value} prevStep={prevStep} activeTab={activeTab} setActiveTab={setActiveTab} patymentDone={patymentDone} setSelectedPaymentCard={setSelectedPaymentCard} dispatchForm={dispatchForm} preview={preview} />
            )}

        </div>
    );
};

export default MultiStepForm;
