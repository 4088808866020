import { Button } from "antd";
import { useNavigate } from "react-router-dom";
import { LINKS } from "routes/constants";

interface FooterProps {
    step: number;
    nextStep: () => void;
    prevStep: () => void;
    price: string;
    type: number;
    setActiveTab: any;
    prevBtn?: boolean;
    setShowPerview?: any;
}

const CreateAppFooter = ({ step, nextStep, prevStep, price, type, setActiveTab, prevBtn, setShowPerview }: FooterProps) => {
    const navigate = useNavigate();    
    return (
        <div style={{ width: '100%', padding: '15px', position: 'absolute', right: '0', bottom: '0px', display: 'flex', justifyContent: 'space-between', alignItems: 'center', background: "#021526", height: '102px' , zIndex:'999' }}>
            <div style={{ width: '50%', textAlign: 'left' }}>
                {step === 4 && (type == 0 || type == 1) && Number(price) > 0 && ( /** show price for payment tab and order tab only */
                    <p style={{
                        fontSize: '30px',
                        fontWeight: '500',    
                        color: '#1F1F1F'
                    }}>$ {Number(price)}</p>     
                )}
                {(step === 2 && prevBtn) && (
                    <Button
                        className="newLight"
                        style={{
                            maxWidth: '250px'
                        }}
                        onClick={() => setShowPerview(false)} >
                        Browse more template   
                    </Button>
                )}
            </div>
            <div style={{ width: '50%', display: 'flex', gap: '10px', justifyContent: 'flex-end' }}>
                {step === 0 && (
                    <Button
                        className="newDark"
                        style={{
                            width: '100%',
                            maxWidth: '137px',   
                            height: "54px",
                            background: "radial-gradient(50% 388.06% at 50% 50%, #0054B5 0%, #01B7FF 100%)"
                        }}
                        onClick={nextStep}>
                        Next
                    </Button>
                )}
                {step === 1 && (
                    <>
                        <Button   
                            className="newLight"
                            style={{
                                maxWidth: '150px'
                            }}
                            onClick={prevStep}>
                            Back
                        </Button>
                        <Button
                            className="newDark"   
                            onClick={nextStep}>
                            Save & Continue
                        </Button>
                    </>
                )}
                {(step === 2 || step === 3) && (     
                    <>
                        <Button
                            className="newLight"
                            style={{
                                maxWidth: '150px'
                            }}
                            onClick={prevStep}>
                            Back   
                        </Button>
                        <Button
                            className="newDark"
                            style={{
                                width: '100%', 
                                maxWidth: '200px'
                            }}
                            onClick={nextStep}>
                            Save & Continue
                        </Button>
                    </>
                )}
                {step === 4 && (
                    <>
                        <Button
                            className="newLight"
                            style={{
                                width: '100%',
                                maxWidth: '150px'
                            }}
                            onClick={prevStep}>
                            Back
                        </Button>
                        <Button
                            className="newDark"
                            style={{
                                width: '100%',
                                maxWidth: type == 2 ? '250px' : '150px'
                            }}
                            onClick={() => {
                                type == 0 && setActiveTab(1); /** open payment tab */
                                type == 1 && nextStep(); /** submit the from */
                                type == 2 && navigate(LINKS.CustomerDashboard); /** go to app page */
                                type == 3 && setActiveTab(1); /** go back tpo payment tab */
                            }}>
                            {type == 0 && `Pay Now`}
                            {type == 1 && `Pay Now`}
                            {type == 2 && `Back to my apps`}
                            {type == 3 && `Save Card`}
                        </Button>
                    </>
                )}
            </div>
        </div>
    )
}
export default CreateAppFooter;