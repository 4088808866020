interface Props {
    handelNotifaction?: () => void;
    height?: any;
    width?: any;
    fill?: any;
}

const Notifactionicon = ({ handelNotifaction, width, height, fill }: Props) => {

    const HandelShowHide = () => {
        if (handelNotifaction) {
            handelNotifaction(); // Safely call the function only if it's defined
        }
    }

    return (
        <svg style={{
            cursor:'pointer'
        }} onClick={HandelShowHide} width={width || "24px"} height={height || "30px"} viewBox="0 0 24 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M3.99998 24.3333V12.3333C3.99998 10.2116 4.84283 8.17675 6.34313 6.67646C7.84342 5.17617 9.87825 4.33331 12 4.33331C14.1217 4.33331 16.1565 5.17617 17.6568 6.67646C19.1571 8.17675 20 10.2116 20 12.3333V24.3333M3.99998 24.3333H20M3.99998 24.3333H1.33331M20 24.3333H22.6666M10.6666 28.3333H13.3333" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M12 4.33332C12.7363 4.33332 13.3333 3.73637 13.3333 2.99999C13.3333 2.26361 12.7363 1.66666 12 1.66666C11.2636 1.66666 10.6666 2.26361 10.6666 2.99999C10.6666 3.73637 11.2636 4.33332 12 4.33332Z" stroke="white" strokeWidth="2" />
        </svg>
    )
}
export default Notifactionicon;