import { useEffect, useState } from 'react';
import './index.sass';
import { getUrlFileURL } from 'pages/customer/CustomerPortal/utils';
import { useSelector } from 'react-redux';
import { RootState } from 'store/store';

interface FormState {
    formData?: any;
    preview?: any;
}

const OrderSummery = ({ formData,preview }: FormState) => {

    const [appForAttachment, setAppForAttachment] = useState<string | null>(null);

    // Access state values
    const UserJob: any = useSelector((state: RootState) => state.createAppReducer.job);

    // Determine image URL
    const getImageURL = async (attachment: string) => {
        try {
            if (attachment) {
                const attachmentURL = await getUrlFileURL(attachment);
                console.log("attachmentURL",attachmentURL);
                
                setAppForAttachment(attachmentURL);
            }
        } catch (error) {
            console.error("Error determining image URL:", error);
            setAppForAttachment(null); // Clear the state on error
        }
    };


    useEffect(() => {
        if ( preview || UserJob?.appImage) {
            const imageName = preview || UserJob?.appImage;
            getImageURL(imageName);
        }
    }, [formData]);

    return (
        <div className="template-section">
            <div className="template-details">
                <div className="detail-item">
                    <span className="detail-label">Template Name:</span>
                    <span className="detail-value">{formData?.fields?.template?.value || UserJob?.appTheme}</span>
                </div>
                <div className="detail-item">
                    <span className="detail-label">App Title:</span>
                    <span className="detail-value">{formData?.fields?.name?.value || UserJob?.appTitle}</span>
                </div>
                <div className="detail-item">
                    <span className="detail-label">App Name:</span>
                    <span className="detail-value">{formData?.fields?.displayName?.value || ''}</span>
                </div>
                <div className="detail-item">
                    <span className="detail-label">App Summary:</span>
                    <span className="detail-value"></span>
                </div>
                <div className="detail-item">
                    <span className="detail-label">Business Website URL:</span>
                    <span className="detail-value">{formData?.fields?.website?.value || UserJob?.website}</span>
                </div>
                <div className="detail-item">
                    <span className="detail-label">Platform:</span>
                    <span className="detail-value">Both</span>
                </div>
                <div className="detail-item">
                    <span className="detail-label">Would you like to inform our team of anything?</span>
                    <span className="detail-value">
                        {formData?.fields?.message?.value || UserJob?.appDescription}
                    </span>
                </div>
                <div className="detail-item">
                    <span className="detail-label">File Attachment:</span>
                    {appForAttachment || UserJob?.appImage ? (
                        <img
                            className="detail-value-image"
                            src={appForAttachment || ""}
                            alt="Attachment Preview"
                        />
                    ) : (
                        <span className="detail-value">No attachment</span>
                    )}
                </div>
            </div>
        </div>

    )
}
export default OrderSummery;