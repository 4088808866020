interface props {
    width: any;
    height: any;
    color: any;
}

const MenuDotsIcone = ({width="24px",height="24px",color="#373737"}:props) => {
    return (
        <svg width={width} height={height} viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg"><path d="M19 16a3 3 0 0 1-3 3 3 3 0 0 1-3-3 3 3 0 0 1 3-3 3 3 0 0 1 3 3zm0 13a3 3 0 0 1-3 3 3 3 0 0 1-3-3 3 3 0 0 1 3-3 3 3 0 0 1 3 3zm0-26a3 3 0 0 1-3 3 3 3 0 0 1-3-3 3 3 0 0 1 3-3 3 3 0 0 1 3 3z" fill={color} /></svg>
    )
}
export default MenuDotsIcone;