import axios, { AxiosRequestConfig } from "axios";
import client from "feathers-client";
import axiosInstance from "utils/axios";
import { SERVICES, URL_PATH } from "utils/constants";
import { addNotification } from 'store/slices/notifactionSlice';

interface PaymentRequest {
    user_Id: any;
    customerId: any;
    paymentMethodId?: string;
    amount?: any;
    currency: string;
    description: string;
    jobId: string;
    paymentType?: string;
    jobName?: string;
}

export enum NotiTypeEnum {
    APP = 'app',
    PAYMENT = 'payment',
    TEMPLATES = 'templates',
    SUBSCRIPTION = 'subscription',
}

export interface Notification {
    _id?: string;
    appid: string;
    image?: string;
    user_id: any;
    type: string;
    message: string;
    is_read: boolean;
}

export const formatTimestamp = (timestamp: string | number): string => {
    const date = new Date(Number(timestamp));

    // Validate the timestamp
    if (isNaN(date.getTime())) {
        //console.error("Invalid timestamp:", timestamp);
        return "Invalid date";
    }

    const options: Intl.DateTimeFormatOptions = {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        hour12: true,
    };

    // Format the date
    let formattedDate = new Intl.DateTimeFormat('en-GB', options).format(date);

    // Replace slashes with dashes and capitalize AM/PM
    formattedDate = formattedDate
        .replace(/\//g, '-') // Replace slashes with dashes
        .replace(/\b(am|pm)\b/g, (match) => match.toUpperCase()); // Capitalize am/pm

    return formattedDate.replace(', ', ' | ');
};



type ValueType = string | number | any;

const checkType = (value: ValueType): string => {
    if (typeof value === 'string') {
        // Check if the string is alphanumeric, contains only letters, or only numbers
        if (/^[a-zA-Z]+$/.test(value)) return 'String';
        if (/^\d+$/.test(value)) return 'Number';
        if (/[a-zA-Z]/.test(value) && /\d/.test(value)) return 'NumberString';
    }
    if (typeof value === 'number') return 'Number';

    return 'Other';
};

export const fetchAppTemplates = async (selectedTemp: any) => {
    if (!selectedTemp) return null; // Early return if selectedTemp is falsy
    const type = checkType(selectedTemp);

    try {
        if (type === 'NumberString') {
            // Fetch app template by ID if it's a 'NumberString'
            const appTemplates = await client.service(SERVICES.APP_TEMPLATES).get(selectedTemp);
            return appTemplates;
        }
        // Otherwise, search by type
        const query = { type: selectedTemp };
        const appTemplates = await client.service(SERVICES.APP_TEMPLATES).find({ query });
        //console.log("appTemplates", appTemplates);

        return appTemplates?.data[0] || null; // Return first template or null if none found
    } catch (error) {
       // console.error('Error fetching app templates:', error);
        throw new Error(`Unable to fetch app templates ${error}`);
    }
};


/** file upload */
export const handleFileUpload = async (file: any) => {
    const reader = new FileReader();

    return new Promise((resolve, reject) => {
        reader.onload = async (e: any) => {
            const base64Data = e.target.result?.toString().split(',')[1]; // Extract base64 part

            if (!base64Data) {
                return reject(new Error('File conversion failed.'));
            }

            const images = [{
                name: file.name,
                base64: base64Data,
                type: file.type,
            }];

            try {
                // Ensure URL_PATH.CLIENT1 is defined correctly and check the URL
                const response = await axios.post(`${URL_PATH.CLIENT1}uploads`, images);

                // Check if response contains the expected data
                if (response?.data?.data?.[0]) {
                    resolve(response.data.data[0]); // Resolve with the uploaded file data
                } else {
                    reject(new Error('Upload failed: No data returned.'));
                }
            } catch (error: any) {
                reject(error); // Reject on error
            }
        };

        reader.onerror = (error) => {
            reject(new Error('File reading failed.'));
        };

        reader.readAsDataURL(file); // Start reading the file
    });
};


/** send email to admin */
export const EmailHandler = async (name: any, file: any, feedback: any) => {
    let INVITE: any;
    let customer_name = name
    INVITE = `Hi Admin,\n\nYou have received feedback from ${customer_name}.\n\nMessage:\n\n${feedback}`;

    try {
        const attachments = [
            {
                fileName: file,
                path: file,
            },
        ];

        // Client Email info@postal.com.au
        const emailObj = {
            to: "info@postal.com.au",
            subject: "Customer Feedback",
            text: INVITE,
            attachments: attachments,
        };

        /* Send email */
        await client.service(SERVICES.SENDEMAIL).create(emailObj);
        return {
            "status": 200,
            "message": "Email has been sent successfully."
        };

    } catch (err: any) {
        return {
            "status": 400,
            "message": err?.message || "An unexpected error occurred. Please try again."
        };
    }
}


/** read image from s3 */
export const getUrlFileURL = async (str: string) => {
    try {
        const response = await axiosInstance.get(`getSignedFileUrl/?fileName=${str}`)
        return response.data.url
    } catch (error) {
        //console.error('getSignedFileUrl error:', error)
    }
}

/** read image from s3 */
export const getUrlFileName = async (str: string) => {
    try {
        const response = await axiosInstance.get(`getSignedFileUrl/?fileName=${str}`)
        return response.data.url
    } catch (error:any) {
        return error?.message;
    }
}

/** read image from s3 */
export const checkFileName = async (str: string) => {
    try {
        const response = await axiosInstance.get(`checkfile/?url=${str}`)
        return response?.data?.exists;
    } catch (error:any) {
        return error?.message;
    }
}



/** create  payment for app  */
export const paymentHandler = async (payload: any, setPatymentDone: any, setActiveTab: any, handelUpdateAppAfterPayment: any, dispatch: any, appImage: any,appTitle:any) => {

    const paymentRequest: PaymentRequest = {
        user_Id: payload?.user_Id,
        customerId: payload?.customerId,
        paymentMethodId: payload?.paymentMethodId,
        amount: payload?.amount * 100,
        currency: "usd",
        description: payload?.description,
        jobId: payload?.jobId,
        paymentType: payload?.paymentType,
    };

    const configE: AxiosRequestConfig = {
        method: 'post',
        maxBodyLength: Infinity,
        url: `${URL_PATH.CLIENT1}create-stripe-payment`,
        headers: {
            'Content-Type': 'application/json',
        },
        data: JSON.stringify(paymentRequest),
    };

    try {
        const apiResult: any = await axios.request(configE);

        if (apiResult?.data.success) {

            
            let notificationMessage = '';
            // If payment successful, activate app and update payment received
            let jobobj: any = {
                updatedAt: new Date()
            };

            if (payload?.paymentType === "interim") {
                jobobj.interimPaymentReceived = true;
                jobobj.interimPayment = payload?.amount;
                jobobj.jobStatus = "review";
                notificationMessage = `${appTitle} App interim payment is successful done.`;
            }

            if (payload?.paymentType === "final") {
                jobobj.finalPaymentReceived = true;
                jobobj.finalPayment = payload?.amount;
                jobobj.jobStatus = "finished";
                notificationMessage = `${appTitle} App final payment is successful done.`;
            }

            let notificationData: Notification = {
                appid: payload?.jobId,
                image: appImage ?? '',
                user_id: payload?.user_Id,
                type: NotiTypeEnum?.PAYMENT,
                message: notificationMessage,
                is_read: false,
            };

            try {
                // Create notification first
                await createNotification(notificationData);
                dispatch(addNotification(notificationData));
                // Handle app update after payment
                handelUpdateAppAfterPayment(jobobj, payload?.jobId, payload?.paymentType, setPatymentDone, setActiveTab, dispatch, payload?.user_Id, appImage,appTitle);

            } catch (error: any) {
                throw new Error(`Error during notification creation or app update: ${error?.message}`);
            }

        }

        if (apiResult.data.status === 500) {
            throw new Error(`Unable to fetch app templates ${apiResult?.data.message}`);
        }

    } catch (err: any) {
        // Log the error for debugging
        //console.error("PaymentHandler Error:", err);

        // Handle known specific error scenarios
        if (axios.isAxiosError(err)) {
            // If the error is an Axios error, check for status codes
            if (err.response?.status === 500) {
                throw new Error(err?.response?.data?.message || "Internal server error. Please try again later.");
            } else if (err.response?.status === 401) {
                throw new Error(err?.response?.data?.message || "Unauthorized. Please check your authentication.");
            } else {
                throw new Error(err?.response?.data?.message || 'An unexpected error occurred.');
            }
        } else {
            throw new Error(err?.response?.data?.message || 'An unexpected error occurred.');
        }
    }
};

/** update job function */
export const handelUpdateAppAfterPayment = async (payload: any, id: any, type: any, setPatymentDone: any, setActiveTab: any, dispatch: any, user_Id: any, appImage: any,appTitle:any) => {

    const config1: AxiosRequestConfig = {
        method: 'patch',
        maxBodyLength: Infinity,
        url: `${URL_PATH.CLIENT1}customerJobUpdate/${id}`,
        headers: {
            'Content-Type': 'application/json',
        },
        data: payload,
    };
    try {
        const updateReq: any = await axios.request(config1);
        let notificationMessage = '';
        if (updateReq?.data?.status === 200) {
            if (type === "interim") {
                setPatymentDone(true);
                setActiveTab(2);
                notificationMessage = `${appTitle} App is ready for demo`;
            }

            if (type === "final") {
                setPatymentDone(true);
                setActiveTab(2);
                notificationMessage = `${appTitle} App is ready for live`;
            }

            let notificationData: Notification = {
                appid: id,
                image: appImage ?? '',
                user_id: user_Id,
                type: NotiTypeEnum?.APP,
                message: notificationMessage,
                is_read: false,
            };

            try {
                // Create notification first
                await createNotification(notificationData);
                dispatch(addNotification(notificationData));
            } catch (error: any) {
                throw new Error(`Error during notification creation or app update: ${error?.message}`);
            }
        } else {
            return updateReq?.data?.message;
        }


    } catch (error: any) {
        const errorMessage = error?.response?.data?.message || error?.message || "An unexpected error occurred.";
        throw new Error(errorMessage || 'An unexpected error occurred.');
    }
};

/** create Subscription */
export const subscriptionPaymentHandler = async (paymentRequest: any, dispatch: any) => {

    const configE = {
        method: 'post',
        maxBodyLength: Infinity,
        url: `${URL_PATH.CLIENT1}create-payment`,
        headers: {
            'Content-Type': 'application/json',
        },
        data: JSON.stringify(paymentRequest),
    };

    try {
        const apiResult = await axios.request(configE);
       // console.log("apiResult", apiResult);

        const { status, message } = apiResult.data;
        if (status === 400 || status === 500) {
            throw new Error(message || 'An unexpected error occurred.');
        } else if (status === 200 || status === 201) {

            let notificationData: Notification = {
                appid: paymentRequest?.jobId,
                user_id: paymentRequest?.user_Id,
                type: NotiTypeEnum?.SUBSCRIPTION,
                message: `Subscription payment is successful done.`,
                is_read: false,
            };

            try {
                // Create notification first
                await createNotification(notificationData);
                dispatch(addNotification(notificationData));
            } catch (error: any) {
                throw new Error(`Error during notification creation or app update: ${error?.message}`);
            }
            return apiResult?.statusText;
        }

        return apiResult;
    } catch (err: any) {
        throw new Error(err.message);
    }
}

export const TestPlans = [
    {
        "_id": "66fbbb39abe8662d01feb279",
        "packageTitle": "Proton Basic",
        "monthlySubscription": 99.9,
        "yearlyDiscount": 10,
        "yearlyDiscountType": "PERCENTAGE",
        "features": [
            {
                "checked": true,
                "value": "Hosting / Support",
                "id": 0.14094410420987624
            }
        ],
        "admin_id": "655c576f8d4e2ad942f64178",
        "stripeMonthlyPriceId": "price_1PRWNHAON2BF3DZ1aIMEQiNw",
        "stripeProductId": "prod_QI6aRF0GSY7aBt",
        "stripeYearlyPriceId": "price_1PRWNHAON2BF3DZ139ukSzQG"
    },
    {
        "_id": "66fbbbb3abe8662d01feb27a",
        "packageTitle": "Proton Pro",
        "monthlySubscription": 149.9,
        "yearlyDiscount": 10,
        "yearlyDiscountType": "PERCENTAGE",
        "features": [
            {
                "checked": true,
                "value": "Hosting / Support",
                "id": 0.8679787217869759
            },
            {
                "checked": true,
                "value": "Promotion / Website",
                "id": 0.4021734840425817
            }
        ],
        "admin_id": "655c576f8d4e2ad942f64178",
        "stripeMonthlyPriceId": "price_1PRWWRAON2BF3DZ1vbPU79fm",
        "stripeProductId": "prod_QI6j71Y3DGcAfd",
        "stripeYearlyPriceId": "price_1PRWWRAON2BF3DZ1HuXG5ZkX"
    },
    {
        "_id": "67078363d84afbf6d5af9ac7",
        "packageTitle": "Proton Pro Plus",
        "monthlySubscription": 199.9,
        "yearlyDiscount": 10,
        "yearlyDiscountType": "PERCENTAGE",
        "features": [
            {
                "checked": true,
                "value": "Hosting / Support",
                "id": 0.9028608529019864
            },
            {
                "checked": true,
                "value": "Promotion / Website",
                "id": 0.6492423173002133
            },
            {
                "checked": true,
                "value": "App Upgrades",
                "id": 0.30241995905075547
            }
        ],
        "admin_id": "655c576f8d4e2ad942f64178",
        "stripeMonthlyPriceId": "price_1PRWXLAON2BF3DZ1l85xgiu8",
        "stripeProductId": "prod_QI6kZxJMjzTlvm",
        "stripeYearlyPriceId": "price_1PRWXLAON2BF3DZ1B4MxC0bN"
    },
    {
        "_id": "671aebab5db4d6db2df97839",
        "packageTitle": "Proton Starter",
        "monthlySubscription": 0,
        "yearlyDiscount": 0,
        "yearlyDiscountType": "PERCENTAGE",
        "features": [
            {
                "checked": true,
                "value": "Access to use Proton",
                "id": 0.2754945134573812
            }
        ],
        "admin_id": "6538f2a02b91053873c3bb69",
        "stripeMonthlyPriceId": "price_1PRC4UAON2BF3DZ11MAwJpFN",
        "stripeProductId": "prod_QHlbIR97pvz1FT",
        "stripeYearlyPriceId": "price_1PRC4VAON2BF3DZ1Ib6yRLac"
    }
];


/** Create Notification Function */
export const createNotification = async (notificationData: any) => {
    try {
        const notificationService = client.service(SERVICES.NOTIFICATIONS);
        const newNotification = await notificationService.create(notificationData);
        return newNotification;
    } catch (error) {
       // console.error('Error creating notification:', error);
        throw error;
    }
};


/** Update Notification Function */
export const updateNotification = async (notificationId: any, updateData: any) => {
    try {
        const notificationService = client.service(SERVICES.NOTIFICATIONS);
        const updatedNotification = await notificationService.patch(notificationId, updateData);
        return updatedNotification;
    } catch (error) {
       // console.error('Error updating notification:', error);
        throw error;
    }
};

/** Mark all notifications as read */
export const markAllAsRead = async () => {
    try {
        const notificationService = client.service(SERVICES.NOTIFICATIONS);

        // Fetch all unread notifications (adjust the query as necessary based on your schema)
        const unreadNotifications = await notificationService.find({
            query: { is_read: false } // Adjust the query if needed
        });
        // If there are unread notifications, update them to mark as read
        if (unreadNotifications.total > 0) {
            const updateData = { is_read: true }; // Assuming 'read' is the field you want to update
            const updatePromises = unreadNotifications?.data?.map((notification: any) =>
                updateNotification(notification._id, updateData)
            );
            // Wait for all updates to complete
            await Promise.all(updatePromises);
        }
    } catch (error) {
       // console.error('Error marking all notifications as read:', error);
        throw error;
    }
};


/** Fetch Notifications Function */
export const fetchNotifications = async (dispatch: any, setUserNotification: any) => {
    try {
        const notificationService = client.service(SERVICES.NOTIFICATIONS); // Accessing the notifications service
        const notifications = await notificationService.find(); // Using 'find' to retrieve all notifications
        
        // Sort notifications by `createdAt` in descending order (latest first)
        const sortedNotifications = notifications?.data.sort(
            (a: any, b: any) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
        );

        return dispatch(setUserNotification(sortedNotifications));
    } catch (error) {
        throw error; // Rethrow error if any
    }
};

/** get job image */
export const getAppImageByJobId = async (jobId: string | number) => {
    try {
        const job = await client.service(SERVICES.JOBS).get(jobId);
        return job?.appImage || null; // Return appImage if it exists, otherwise return null
    } catch (error: any) {
       // console.error('Error fetching appImage:', error);
    }
};

/** get job  */
export const getAppByJobId = async (jobId: string | number) => {
    try {
        const job = await client.service(SERVICES.JOBS).get(jobId);
        return job || null; // Return appImage if it exists, otherwise return null
    } catch (error: any) {
       // console.error('Error fetching appImage:', error);
    }
};