import { Button } from "antd";
import { useNavigate } from "react-router-dom";

const NotFound = () => {
  const navigate = useNavigate();

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "80vh",
        textAlign: "center",
        color: "white",
        fontFamily: "Arial, sans-serif",
      }}
    >
      <h2 style={{ fontSize: "2rem", margin: "10px 0" }}>No Content Found</h2>
      <Button
        className="newDark"
        style={{
          width: '100%',
          height: "54px",
          background: "radial-gradient(50% 388.06% at 50% 50%, #0054B5 0%, #01B7FF 100%)"
        }}
        onClick={() => navigate("/")}>
        Go to Home
      </Button>
    </div>
  );
};

export default NotFound;
