import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// Define the structure of the app data
export interface AppData {
  id: string;
  isOpen:boolean;
  // You can add more properties here if needed
}

// Define the structure of the slice state
interface AppDataState {
  appDemo: AppData | null; // Use null to indicate no data
  appLive: AppData | null;  // Use null to indicate no data
}

// Initialize the state
const initialState: AppDataState = {
  appDemo: null, // Initialize to null
  appLive: null, // Initialize to null
};

// Create the slice
export const appDataSlice = createSlice({
  name: 'APPDATASLICE',
  initialState,
  reducers: {
    setAppDemo: (state, action: PayloadAction<AppData>) => {
      state.appDemo = action.payload; // Use AppData type for payload
    },
    setAppLive: (state, action: PayloadAction<AppData>) => {
      state.appLive = action.payload; // Use AppData type for payload
    },
  },
});

// Export actions and reducer
export const { setAppDemo, setAppLive } = appDataSlice.actions;
export default appDataSlice.reducer;