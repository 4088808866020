interface Props {
    width?: string | number;
    height?: string | number;
}

const CreateAppIcon = ({ height = "22px", width = "22px" }: Props) => {
    return (
        <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13.75 1.83301H5.50002C5.01379 1.83301 4.54747 2.02616 4.20366 2.36998C3.85984 2.7138 3.66669 3.18011 3.66669 3.66634V18.333C3.66669 18.8192 3.85984 19.2856 4.20366 19.6294C4.54747 19.9732 5.01379 20.1663 5.50002 20.1663H16.5C16.9863 20.1663 17.4526 19.9732 17.7964 19.6294C18.1402 19.2856 18.3334 18.8192 18.3334 18.333V6.41634L13.75 1.83301Z" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M12.8333 1.83301V5.49967C12.8333 5.9859 13.0265 6.45222 13.3703 6.79604C13.7141 7.13985 14.1804 7.33301 14.6666 7.33301H18.3333" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M7.33331 11H14.6666" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M9.16669 10.083V11.9163" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M7.33331 15.583H14.6666" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M12.8333 14.667V16.5003" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}
export default CreateAppIcon;