import { useContext, useEffect, useMemo, useState } from "react";
import FromHeader from "pages/customer/CustomerPortal/CreateApp/Step/formHeader";
import { AuthContext } from "context/AuthContext";
import GreenTickSvg from "components/SvgIcons/greetick";
import { Button } from "antd";
import { SubscriptionFetchingSort } from "pages/subscriptions/utils";
import { subscriptionPaymentHandler, TestPlans } from "pages/customer/CustomerPortal/utils";
import { toast } from "react-toastify";
import Loader from "../Loader/Loader";
import { useDispatch } from "react-redux";

interface Step1Props {
    userJob?: any;
    type?: string;
}

const AppSubscriptionCard = ({ userJob, type }: Step1Props) => {
    const dispatch = useDispatch();
    const auth = useContext(AuthContext);
    const [selectPlan, setSelectPlan] = useState<any>(null);
    const [currentPlan, setCurrentPlan] = useState<any>([]);
    const [subscriptions, setSubscriptions] = useState<any>([]);
    const [stripeProductId, setStripeProductId] = useState<any>(null);
    const [isLoader, setIsLoader] = useState<boolean>(false);

    const handelSelectPlan = async (selectPlan: any, stripeProductId: any) => {
        setSelectPlan(selectPlan);
        setStripeProductId(stripeProductId);
    };

    const subscriptionHandler = async (selectPlan: any, stripeProductId: any) => {
        setIsLoader(true);
        try {
            if (auth?.user?._id && selectPlan && stripeProductId && userJob?.id) {
                const payload: any = {
                    "user_Id": auth?.user?._id,
                    "stripeMonthlyPriceId": selectPlan,
                    "stripeProductId": stripeProductId,
                    "jobId": userJob?.id,
                    "paymentType": "MONTHLY"
                };
                const res: any = await subscriptionPaymentHandler(payload, dispatch);
                if (res?.data?.redirectUrl) {
                    // Redirect the user
                    window.location.href = res?.data?.redirectUrl;
                }
                //console.log("res", res);
                setIsLoader(false);
            } else {
                toast.error("All fields are required: user_id, selectPlan, stripeProductId, and userJob_id.");
            }
        } catch (error: any) {
            setIsLoader(false);
            toast.error(error?.message || "Somthing went wrong");
        }
    }

    const subscriptionsData = useMemo(async () => {
        if (auth) {
            const subscriptions = await SubscriptionFetchingSort();
            if (Array.isArray(subscriptions?.data)) {
                return subscriptions.data;
            }
        }
        return [];
    }, [auth]);

    useEffect(() => {
        /** For live */
        if (auth?.user?.subscriptionPriceId && subscriptions) {
            /** For local */
            //if (auth?.user?.subscriptionPriceId && TestPlans) {
            setSelectPlan(auth.user.subscriptionPriceId);

            /** For local */
            // const cuPlan = TestPlans.filter((item: any) =>
            //     item?.stripeMonthlyPriceId === auth.user.subscriptionPriceId
            // );
            /** For live */
            const cuPlan = subscriptions.filter((item: any) =>
                item?.stripeMonthlyPriceId === auth.user.subscriptionPriceId
            );
            // Update the current plan if matches are found
            if (cuPlan.length > 0) {
                setCurrentPlan(cuPlan);
            } else {
                console.warn("No matching plan found for the subscriptionPriceId.");
            }
        }
        /** For local */
        //setSubscriptions(TestPlans);
        /** For live */
        subscriptionsData.then((data) => {
            setSubscriptions(data);
        });
    }, [auth]);

    return (
        <div style={{ width: '100%' }}>
            <div
                style={{
                    height: '80vh',
                    display: 'flex',
                    flexDirection: 'column',
                    overflow: 'auto'
                }}
            >
                <div className="main_card" style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '16px'
                }}>
                    <FromHeader
                        title={"Proton Subscription Plans"}
                        desc={"Get the most out of your app with the right plan. Start with a 1-month free Proton Pro subscription!"}
                    />
                    <div style={{
                        display: 'flex',
                        justifyContent: "center",
                        flexWrap: 'wrap',
                        gap: 25,
                        marginTop: "-3rem"
                    }}>
                        {isLoader ?
                            < Loader />
                            :
                            <div style={{
                                display: 'flex',
                                flexDirection: 'column',
                                gap: '16px',
                                width: "100%"
                            }}>
                                {
                                    currentPlan && currentPlan[0]?.packageTitle && auth?.user?.subscriptionPriceId == selectPlan &&
                                    <div>
                                        <h4 style={{
                                            fontSize: '20px',
                                            fontWeight: 'bold'
                                        }} >
                                            Current Plan: {currentPlan && currentPlan[0]?.packageTitle}
                                        </h4>
                                    </div>
                                }
                                <div style={{
                                    display: 'flex',
                                    flexWrap: 'wrap',
                                    gap: '40px'
                                }}>
                                    {subscriptions && subscriptions.length > 0 &&
                                        subscriptions.map((item: any) => (
                                            <div
                                                key={item?._id}
                                                className="plans"
                                                style={{
                                                    border: selectPlan != item?.stripeMonthlyPriceId ? '1px solid #222647' : '1px solid #929292',
                                                    borderRadius: '4px',
                                                    //width: '100%',
                                                    //background: '#000B14',
                                                    padding: '20px',
                                                    minWidth: '324px',
                                                    width: "23%",
                                                    minHeight: '476px',
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    justifyContent: 'space-between',
                                                    background: "#000B1499"
                                                }}
                                                onClick={() => handelSelectPlan(item?.stripeMonthlyPriceId, item?.stripeProductId)}
                                            >
                                                <div style={{
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    gap: '40px'
                                                }}>
                                                    <div style={{
                                                        display: 'flex',
                                                        flexDirection: 'column',
                                                        gap: '12px'
                                                    }}>
                                                        <h4 style={{
                                                            color: '#1F1F1F',
                                                            fontSize: '20px',
                                                            textAlign: 'center',
                                                            background: 'radial-gradient(circle, rgba(0, 84, 181, 1) 0%, rgba(1, 183, 255, 1) 100%)',
                                                            WebkitBackgroundClip: 'text',
                                                            WebkitTextFillColor: 'transparent',
                                                            whiteSpace: 'nowrap',
                                                            fontFamily: "'Mulish', sans-serif",
                                                            fontWeight:'600'
                                                        }}>
                                                            {item?.packageTitle}
                                                        </h4>
                                                        <h2
                                                            style={{
                                                                color: '#EDEDED',
                                                                fontSize: '30px',
                                                                fontWeight: '600',
                                                                textAlign: 'center',
                                                                fontFamily: "'Inter', sans-serif"
                                                            }}
                                                        >
                                                            ${item?.monthlySubscription == 0 ? '0.00' : item?.monthlySubscription} / mo
                                                        </h2>
                                                    </div>

                                                    <ul style={{
                                                        listStyle: 'none',
                                                        display: 'flex',
                                                        flexDirection: 'column',
                                                        gap: '12px'
                                                    }}>
                                                        {item?.features && item?.features?.length > 0 &&
                                                            item?.features.map((feature: any) => (
                                                                <li key={feature?.id} style={{
                                                                    display: 'flex',
                                                                    alignItems: 'center',
                                                                    gap: '24px'
                                                                }}>
                                                                    {feature?.checked && <GreenTickSvg classstyle="gradient" color={"#222647"} />}
                                                                    <span style={{
                                                                        fontSize: '18px',
                                                                        color: '#BFBFBF',
                                                                        fontFamily: "'Mulish', sans-serif"
                                                                    }}>{feature?.value}</span>
                                                                </li>
                                                            ))
                                                        }
                                                    </ul>
                                                </div>
                                                {type !== 'preview' && <div style={{
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    gap: '10px',
                                                    minHeight: '79px'
                                                    }}>
                                                    <div style={{
                                                        textAlign: 'center'
                                                    }}>
                                                        <Button
                                                            onClick={() => {
                                                                handelSelectPlan(item?.stripeMonthlyPriceId, item?.stripeProductId)
                                                                subscriptionHandler(item?.stripeMonthlyPriceId, item?.stripeProductId);
                                                            }}
                                                            className="newLight"
                                                            style={{
                                                                width: '100%',
                                                                maxWidth: '286px',
                                                                background: 'radial-gradient(circle, rgba(0, 84, 181, 1) 0%, rgba(1, 183, 255, 1) 100%)',
                                                                WebkitBackgroundClip: 'text',
                                                                WebkitTextFillColor: 'transparent',
                                                                whiteSpace: 'nowrap',
                                                                fontFamily: "'Mulish', sans-serif",
                                                            }}
                                                        >
                                                            Choose Plan
                                                        </Button>
                                                    </div>
                                                    <p style={{
                                                        textAlign: 'center'
                                                    }}>{item?.note}</p>
                                                </div>}
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>
                        }

                    </div>
                </div>
            </div>
        </div>
    )
}
export default AppSubscriptionCard;