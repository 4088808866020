const LightOutLined = () => {
    return (
        <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_33456_512)">
        <path d="M11.5 3.5V1.5M11.5 19.5V21.5M5.91421 5.91421L4.5 4.5M17.228 17.228L18.6422 18.6422M3.5 11.5H1.5M19.5 11.5H21.5M17.2285 5.91421L18.6427 4.5M5.9147 17.228L4.50049 18.6422M11.5 16.5C8.73858 16.5 6.5 14.2614 6.5 11.5C6.5 8.73858 8.73858 6.5 11.5 6.5C14.2614 6.5 16.5 8.73858 16.5 11.5C16.5 14.2614 14.2614 16.5 11.5 16.5Z" stroke="url(#paint0_radial_33456_512)" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        </g>
        <defs>
        <radialGradient id="paint0_radial_33456_512" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(11.5 11.5) scale(10 77.6119)">
        <stop stopColor="#0054B5"/>
        <stop offset="1" stopColor="#01B7FF"/>
        </radialGradient>
        <clipPath id="clip0_33456_512">
        <rect width="23" height="23" fill="white"/>
        </clipPath>
        </defs>
        </svg>
        

    )
}
export default LightOutLined;