import { Dispatch, useContext, useReducer, useState } from "react";
import FromHeader from "pages/customer/CustomerPortal/CreateApp/Step/formHeader";
import PlaceholderSvg from 'components/SvgIcons/placeholder';
import { UPDATE_FORM, onFocusOut, onInputChange, validateInput, resetForm, initialState, formReducer } from "components/lib/validation/appDemo";
import { Button } from 'antd';
import { toast } from "react-toastify";
import { AuthContext } from "context/AuthContext";
import SimplePopup from "components/common/popup";
import { EmailHandler, handleFileUpload } from "pages/customer/CustomerPortal/utils";
import { useSelector } from "react-redux";
import { RootState } from "store/store";
import DummyImageFeedback from "../../../assets/dummy-image-feedback.png";

interface Step1Props {
    showPaymentPage?: any;
}

const confirmValidity = (formState: any, dispatch: Dispatch<any>) => {
    let isFormValid = true;
    for (let name in formState) {
        const item = formState[name]
        const { value } = item
        const { hasError, error } = validateInput(name, value, formState)
        if (hasError) {
            isFormValid = false
        }
        if (name) {
            dispatch({
                type: UPDATE_FORM,
                data: {
                    name,
                    value,
                    hasError,
                    error,
                    touched: true,
                    isFormValid,
                },
            })
        }
    }
    return isFormValid;
}

const AppDemoCard = ({ showPaymentPage }: Step1Props) => {
    const [formData, dispatchForm] = useReducer(formReducer, initialState);
    const [file, setFile] = useState<string | null>(null);
    const [payment, setPayment] = useState<boolean>(false);
    const [SucessPop, setSucessPop] = useState<boolean>(false);
    const auth = useContext(AuthContext);
    const appDemo = useSelector((state: RootState) => state.appDataReducer.appDemo);
    const appLive = useSelector((state: RootState) => state.appDataReducer.appLive);

    // Handle image removal
    const handleRemoveImage = () => {
        setFile(null);
        onInputChange("attachment", '', dispatchForm, formData);
    };

    const handleChangeFile = async (e: React.ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files?.[0]; // Get the first file
        if (file) {
            const fileUrl = URL.createObjectURL(file);
            setFile(fileUrl);
            try {
                // Ensure that the fileupload is awaited and properly assigned
                const fileupload: any = await handleFileUpload(file);

                if (fileupload) {
                    onInputChange("attachment", fileupload, dispatchForm, formData);
                }
            } catch (error) {
                toast.error(`Error during file upload. ${error}`);
            }
        }
    };

    const onSubmit = async (e: any) => {
        e.preventDefault();
        const formVaild = confirmValidity(formData, dispatchForm);

        if (formVaild) {
            const name = auth?.user?.name;

            const EmailSentRes = await EmailHandler(name, formData?.attachment?.value, formData?.feedback?.value);

            if (EmailSentRes?.status == 200) {
                setSucessPop(true);
            } else {
                toast.error(EmailSentRes?.message, {
                    position: toast.POSITION.BOTTOM_CENTER,
                })
            }
            dispatchForm(resetForm());
        } else {
            toast.error(' Please fill in all required fields.', {
                position: toast.POSITION.BOTTOM_CENTER,
            })
        }
    }

    return (
        <div style={{ width: '100%' }}>
            <div
                style={{
                    height: '80vh',
                    display: 'flex',
                    flexDirection: 'column'
                }}
            >
                <div className="main_card">
                    <FromHeader
                        title={"App Demo"}
                        desc={"Experience a live preview of your app in action. Interact with its features, explore the design, and get a feel for the user experience before finalizing."}
                    />
                    <form onSubmit={onSubmit} style={{ marginTop: "-60px" }}>
                        <div style={{
                            display: 'flex',
                            justifyContent: "center",
                            background: '#2226470D',
                            padding: "25px",
                            gap: 10
                        }}>
                            <div style={{
                                width: "43%"
                            }}>
                                <div style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                }}>
                                    <div>
                                        {/* <PlaceholderSvg width="330px" height="500" /> */}
                                        <img src={DummyImageFeedback} style={{ objectFit: 'contain', width: "100%" }} height="500" alt="DummyImageFeedback" />
                                    </div>
                                </div>
                            </div>
                            <div style={{
                                width: "65%",
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'space-between'
                            }}>
                                <div style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                }}>
                                    <div style={{
                                        width: '100%',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        borderRadius: '4px',
                                        gap: '20'
                                    }}>
                                        <label
                                            style={{
                                                fontFamily: 'Mulish, sans-serif',
                                                fontWeight: 600,
                                                fontSize: '18px',
                                                lineHeight: '31.63px',
                                                letterSpacing: '0%'
                                            }}
                                        >
                                            Your Feedback
                                        </label>

                                        {/* Textarea input */}
                                        <textarea
                                            name="feedback"
                                            value={typeof formData.feedback.value === "string" ? formData.feedback.value : ''}
                                            className={`${formData.feedback.touched && formData.feedback.hasError ? "error" : ""}`}
                                            onChange={e => {
                                                onInputChange("feedback", e.target.value, dispatchForm, formData);
                                            }}
                                            onBlur={e => {
                                                onFocusOut("feedback", e.target.value, dispatchForm, formData);
                                            }}
                                            rows={4}
                                            style={{
                                                width: '100%',
                                                height: "150px",
                                                padding: '10px',
                                                borderRadius: '4px',
                                                border: '1px solid #ccc',
                                                resize: 'vertical',
                                                fontSize: '14px',
                                                marginBottom: '5px',
                                                background: "transparent",
                                                color: "white"
                                            }}
                                        />
                                        {formData.feedback?.touched && formData.feedback?.hasError && (
                                            <div className="error">{formData.feedback?.error}</div>
                                        )}
                                    </div>
                                    <div style={{ width: "100%" }}>
                                        <label
                                            style={{
                                                fontFamily: "Mulish, sans-serif",
                                                fontWeight: 600,
                                                fontSize: "18px",
                                                lineHeight: "31.63px",
                                                display: "block",
                                                marginBottom: "5px",
                                            }}
                                        >
                                            File Attachment
                                        </label>

                                        <div
                                            style={{
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "space-between",
                                                border: "1px solid #ccc",
                                                borderRadius: "4px",
                                                padding: "5px",
                                                background: "transparent",
                                                color: "white",
                                            }}
                                        >
                                            <span style={{
                                                flexGrow: 1,
                                                paddingRight: "10px",
                                                fontFamily: "Mulish, sans-serif",
                                                fontWeight: 600,
                                                fontSize: "15px",
                                                lineHeight: "31.63px",
                                                letterSpacing: "0%",
                                                color: "#929292"
                                            }}>
                                                {"No file selected"}
                                            </span>
                                            <label
                                                style={{
                                                    background: "#007bff",
                                                    color: "white",
                                                    padding: "10px 15px",
                                                    borderRadius: "4px",
                                                    cursor: "pointer",
                                                    fontWeight: 700,
                                                    fontFamily: "Inter, sans-serif",
                                                    fontSize: "14px",
                                                    lineHeight: "19.6px",
                                                    letterSpacing: "0%",
                                                    textAlign: "center",
                                                    position: "relative"
                                                }}
                                            >
                                                Browse
                                                <input
                                                    type="file"
                                                    name="attachment"
                                                    onChange={handleChangeFile}
                                                    style={{
                                                        position: "absolute",
                                                        left: 0,
                                                        top: 0,
                                                        opacity: 0,
                                                        width: "100%",
                                                        height: "100%",
                                                        cursor: "pointer",
                                                    }}
                                                />
                                            </label>
                                        </div>

                                        {file && (
                                            <div
                                                style={{
                                                    position: "relative",
                                                    marginTop: "10px",
                                                    border: "1px solid #ccc",
                                                    padding: "10px",
                                                    display: "flex",
                                                    alignItems: "center",
                                                    width:'125px',
                                                    marginBottom:'20px'
                                                }}
                                            >
                                                <img
                                                    src={file}
                                                    alt="Preview"
                                                    style={{ width: "100px", height: "100px", objectFit: "contain" }}
                                                />
                                                <button
                                                    onClick={handleRemoveImage}
                                                    style={{
                                                        position: "absolute",
                                                        right: "-10px",
                                                        top: "-10px",
                                                        fontSize: "16px",
                                                        fontWeight: 600,
                                                        border: "1px solid #ccc",
                                                        borderRadius: "50%",
                                                        width: "25px",
                                                        height: "25px",
                                                        textAlign: "center",
                                                        lineHeight: "22px",
                                                        cursor: "pointer",
                                                        color: "#000",
                                                        background: "#fff",
                                                    }}
                                                >
                                                    ✖
                                                </button>
                                            </div>
                                        )}
                                    </div>
                                </div>
                                {!payment && <div style={{
                                    display: 'flex',
                                    justifyContent: 'flex-end'
                                }}>
                                    <Button style={{ background: "#0077FF", fontWeight: 0, width: '100%'}} htmlType="submit" className="newLight">Share your Feedback</Button>
                                </div>}
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            {SucessPop && <SimplePopup title="Your Feedback is share to us" desc="We value your insights! Let us know your thoughts to help us improve and deliver a better experience." setSucessPop={setSucessPop} SucessPop={SucessPop} setFile={setFile} />}
        </div>
    )
}
export default AppDemoCard;