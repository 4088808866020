interface Props {
  width?: string | number;
  height?: string | number;
}

const MyAppsIcon = ({ height = "22px", width = "22px" }: Props) => {
  return (
    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M3.66669 20.1663H16.5C16.9863 20.1663 17.4526 19.9732 17.7964 19.6294C18.1402 19.2856 18.3334 18.8192 18.3334 18.333V6.41634L13.75 1.83301H5.50002C5.01379 1.83301 4.54747 2.02616 4.20366 2.36998C3.85984 2.7138 3.66669 3.18011 3.66669 3.66634V7.33301" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M12.8333 1.83301V5.49967C12.8333 5.9859 13.0265 6.45222 13.3703 6.79604C13.7141 7.13985 14.1804 7.33301 14.6666 7.33301H18.3333" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M2.75 13.75H8.25" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M5.5 11V16.5" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
      
    </svg>

  )
}
export default MyAppsIcon;