import React, { useState } from 'react';

const AppPlatform: React.FC = () => {
    const [activePlatform, setActivePlatform] = useState<string | null>('Android'); // State to track the active button

    const handleClick = (platform: string) => {
        setActivePlatform(platform); // Set the active platform
        console.log("Platform selected:", platform); // Log selected platform
    };

    return (
        <div className='appPlatform modeFilter'>
            <p className='paraFilter'> App Platform </p>
            <div className='filterModeButton'>
                <button onClick={() => handleClick('Android')} className={activePlatform === 'Android' ? 'active' : ''}>Android</button>
                <button onClick={() => handleClick('iOS')} className={activePlatform === 'iOS' ? 'active' : ''}>iOS</button>
                <button onClick={() => handleClick('Both')} className={activePlatform === 'Both' ? 'active' : ''}> Both </button>
            </div>
        </div>
    );
};

export default AppPlatform;
