interface HeadProps {
    title: String;
    desc?: String;
    step?:number;
    name?: String;
}

const FromHeader = ({ title, desc,step,name }: HeadProps) => {
    return (
        <div className="main_card"  style={{
            width: step == 0 ? '100%' : '100%',
           

          }}>
           {step && step > 0 ? <div>
                <p className="TemplateSteps"> {step}/4 {name}</p>
            </div>
            : ''
            }
            <div className="header_new" >
                <div className="card_top_heading">
                    <h4 className="Titlesteps">{title}</h4>
                </div>
                <div className="desTemplate">
                    {desc}
                </div>
            </div>
        </div>
    )
}
export default FromHeader;