
const ActiveGreen = () => {
    return (
        <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="11" cy="11" r="10.5" fill="#00DD00" stroke="#00DD00" />
            <path d="M5.75 11.75L9.25 15.25L16.25 7.75" stroke="#000B14" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}

export default ActiveGreen
