interface Props {
  width?: string | number;
  height?: string | number;
}

const SubscriptionPlanIcon = ({ height = "22px", width = "22px" }: Props) => {
  return (
    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M7.33331 1.83334V5.50001" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M14.6667 1.83334V5.50001" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M19.25 12.8333V5.49999C19.25 5.01376 19.0568 4.54744 18.713 4.20363C18.3692 3.85981 17.9029 3.66666 17.4167 3.66666H4.58333C4.0971 3.66666 3.63079 3.85981 3.28697 4.20363C2.94315 4.54744 2.75 5.01376 2.75 5.49999V18.3333C2.75 18.8196 2.94315 19.2859 3.28697 19.6297C3.63079 19.9735 4.0971 20.1667 4.58333 20.1667H11.9167" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M2.75 9.16666H19.25" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M14.6667 18.3333L16.5 20.1667L20.1667 16.5" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  )
}
export default SubscriptionPlanIcon;