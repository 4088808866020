import React, { useCallback, useContext, useEffect, useState } from 'react'

import { Card } from 'antd'
import { AuthContext } from 'context/AuthContext'
import client from 'feathers-client'
import { SERVICES } from 'utils/constants'

import { onInputChange } from '../../../../../../components/lib/validation/createApp'
import FilterSvg from 'components/SvgIcons/filtter'
import PlaceholderSvg from 'components/SvgIcons/placeholder'
import SelectOptionDropdown from 'components/common/dropdown'
import PreviewTemplateCard from 'components/common/prevTemplate'
import SearchBarInput from 'components/common/searchBar'

import LayoutDummy from '../../../../../../assets/layout-dummy.png'

import CreateAppFooter from '../../footer'
import FromHeader from '../formHeader'
import './index.sass'

interface Step1Props {
  formData: any
  nextStep: () => void
  prevStep: () => void
  step: number
  dispatchForm: any
  name: string
}

interface Template {
  icon: string
  type: string
  description: string
  deposit: number
  yearlyDiscount: number
  monthlySubscription: number
  finalPayment: number
  added: number
  updatedAt: number
  interim: number
  id: string
}

const Step2 = ({
  formData,
  dispatchForm,
  nextStep,
  step,
  name,
  prevStep,
}: Step1Props) => {
  const [selectedCard, setSelectedCard] = useState<number | null>(null)
  const [selectedTemp, setSelectedTemp] = useState<string | null>(null)
  const auth = useContext(AuthContext)
  const [appTemplates, setAppTemplates] = useState<Template[]>([])
  const [appTemplatesCount, setAppTemplatesCount] = useState<number>(0)
  const [showPerview, setShowPerview] = useState<boolean>(false)
  const [sortOption, setSortOption] = useState<String | number>(1)
  const [filterOption, setFilterOption] = useState('')

  const fetchAppTemplates = useCallback(async () => {
    if (auth) {
      let query = {
        $sort: {
          added: 1,
        },
      }
      const appTemplates = await client
        .service(SERVICES.APP_TEMPLATES)
        .find({ query })

      if (Array.isArray(appTemplates?.data)) {
        setAppTemplates(appTemplates.data)
        setAppTemplatesCount(appTemplates.total || 0)
      }
    }
  }, [auth])

  // Fetch app templates on component mount with initial effect
  useEffect(() => {
    fetchAppTemplates()
  }, [fetchAppTemplates])

  const fetchAppTemplatesBySearch = async (
    value?: string | number,
    type?: string
  ) => {
    try {
      let appTemplates
      const trimmedValue = typeof value === 'string' ? value.trim() : value

      if (type === 'search' && trimmedValue) {
        const query = { type: trimmedValue }
        appTemplates = await client
          .service(SERVICES.APP_TEMPLATES)
          .find({ query })
      } else if (
        type === 'sort' &&
        (trimmedValue === 1 || trimmedValue === -1)
      ) {
        const query = {
          $sort: { added: trimmedValue },
        }
        appTemplates = await client
          .service(SERVICES.APP_TEMPLATES)
          .find({ query })
      } else {
        if (trimmedValue == '') {
          const query = {
            $sort: { added: 1 },
          }
          appTemplates = await client
            .service(SERVICES.APP_TEMPLATES)
            .find({ query })
        }
      }

      if (Array.isArray(appTemplates?.data)) {
        setAppTemplates(appTemplates.data)
        setAppTemplatesCount(appTemplates.total || 0)
      }
    } catch (error) {
      console.error('Error fetching app templates:', error)
    }
  }

  const handleSortChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedOption = Number(event.target.value)
    setSortOption(selectedOption)
    fetchAppTemplatesBySearch(selectedOption, 'sort')
  }

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const searchValue = event.target.value
    fetchAppTemplatesBySearch(searchValue, 'search')
  }

  const sortOptions = [
    { value: 1, label: 'ASC' },
    { value: -1, label: 'DESC' },
  ]

  const filterOptions = [
    { value: 'name', label: 'Name' },
    { value: 'price', label: 'Price' },
    { value: 'type', label: 'Type' },
  ]

  const handleFilterChange = (selectedOption: any) => {
    console.log('handleFilterChange', selectedOption)
    // setFilterOption(selectedOption);
  }
  const handelPreview = (id: string) => {
    setShowPerview(!showPerview)
    setSelectedTemp(id)
  }

  const handleCardClick = (index: any) => {
    setSelectedCard(index);
  }


  return (
    <div style={{ width: '100%' }}>
      {showPerview ? (
        <PreviewTemplateCard
          step={step}
          name={name}
          nextStep={nextStep}
          prevStep={prevStep}
          setShowPerview={setShowPerview}
          selectedTemp={selectedTemp}
        />
      ) : (
        <>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <div className="main_card">
              <FromHeader
                title={'Select Your App Template'}
                desc={
                  'Browse through our library of professionally crafted app templates, designed to streamline your development process.'
                }
                step={step}
                name={name}
              />
              <div
                className=""
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                }}
              >
                <SearchBarInput onchange={handleSearchChange} />
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    gap: '10px',
                  }}
                >
                  <SelectOptionDropdown
                    name={'filter'}
                    option={filterOptions}
                    title={'Filter'}
                    icon={<FilterSvg />}
                    onchange={handleFilterChange}
                    style={{
                      width: '124px',
                      paddingLeft: '40px',
                      background: 'transparent',
                      color: 'white'
                    }}
                    value={``}
                  />
                  <SelectOptionDropdown
                    name={'sort'}
                    option={sortOptions}
                    title={'Sort By'}
                    onchange={handleSortChange}
                    style={{
                      width: '250px',
                      background: 'transparent',
                      color: 'white'
                    }}
                    value={``}
                  />
                </div>
              </div>
              <div style={{ margin: '70px 0px 200px' }}>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    flexWrap: 'wrap',
                    alignItems: 'center',
                    padding: '0',
                    gap: '20px',
                    width: '100%',
                    height: '100%',
                    justifyContent: 'flex-start',
                  }}
                >
                  {appTemplates &&
                    appTemplates.map((item, index) => (
                      <Card 
                        key={index + 1}
                        onClick={() => {
                          handleCardClick(index + 1)
                          onInputChange(
                            'template',
                            item?.type,
                            dispatchForm,
                            formData
                          )
                          onInputChange(
                            'template_id',
                            item?.id,
                            dispatchForm,
                            formData
                          )
                          onInputChange(
                            'price',
                            String(item?.deposit),
                            dispatchForm,
                            formData
                          )
                        }}
                        style={{
                          // width:
                          //   appTemplatesCount && appTemplatesCount == 1
                          //     ? '30%'
                          //     : appTemplatesCount > 0
                          //       ? '25%'
                          //       : '50%',solid 
                          
                          display: 'flex', 
                          flexDirection: 'row',
                          flexWrap: 'wrap',
                          gap: '0',
                          background: '#00020333',
                          width:'100%',
                          color: '#EDEDED',
                          maxWidth: '342px',
                          maxHeight: '450px'
                        }}
                      >
                        <div
                          className={selectedCard == index + 1 ? 'cardStyles active' : 'cardStyles'}
                          style={{
                            width: '100%',
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '0',
                          }}
                        >
                          <div
                            style={{
                              width: '100%',
                              maxHeight: '198px'
                            }}
                          >
                            {/* <PlaceholderSvg width={'345'} height={'198'} /> */}
                            <img
                              src={LayoutDummy}
                              alt="image-thumbnail"
                              style={{ objectFit: 'cover', width: '100%' }}
                            />
                          </div>
                          <div
                            style={{
                              background: 'transparent',
                              width: '100%',
                              padding: '20px',
                              display: 'flex',
                              flexDirection: 'column',
                              justifyContent: 'space-between',
                              minHeight: '229px',
                            }}
                          >
                            <div
                              className="steps-heading"
                              style={{
                                fontFamily: 'Inter',
                                fontSize: '24px',
                                fontWeight: '600',
                                lineHeight: '29.05px',
                                textAlign: 'left',
                                textUnderlinePosition: 'from-font',
                                textDecorationSkipInk: 'none',
                              }}
                            >
                              {item?.type}
                            </div>
                            <div
                              className="steps-heading"
                              style={{
                                fontFamily: 'Inter',
                                fontSize: '20px',
                                fontWeight: '500',
                                lineHeight: '24.2px',
                                textAlign: 'left',
                                textUnderlinePosition: 'from-font',
                                textDecorationSkipInk: 'none',
                                color: '#0077FF',
                              }}
                            >
                              $50
                            </div>
                            <div style={{
                              fontFamily: "'Mulish', sans-serif",
                              fontSize:'16px'
                            }}>{item?.description}</div>
                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'center',
                                gap: '1rem',
                                marginTop:'10px'
                              }}
                            >
                              <button
                                type="button"
                                className=""
                                onClick={() => handelPreview(item?.id)}
                                style={{
                                  padding: '10px 20px',
                                  width: '132px',
                                  height: '44px',
                                  paddingTop: '10px',
                                  gap: '20px',
                                  borderRadius: '6px',
                                  border: '2px solid #929292',
                                  background: 'none',
                                  color: 'white',
                                  fontWeight: '700',
                                  cursor: "pointer"
                                }}
                              >
                                Preview
                              </button>
                              <div
                                onClick={() => {
                                  onInputChange(
                                    'template',
                                    item?.type,
                                    dispatchForm,
                                    formData
                                  )
                                  onInputChange(
                                    'template_id',
                                    item?.id,
                                    dispatchForm,
                                    formData
                                  )
                                  onInputChange(
                                    'price',
                                    String(item?.deposit),
                                    dispatchForm,
                                    formData
                                  )
                                }}
                                style={{
                                  padding: '10px 4px',
                                  width: '132px',
                                  height: '44px',
                                  paddingTop: '10px',
                                  gap: '20px',
                                  borderRadius: '6px',
                                  color: 'white',
                                  fontWeight: '700',
                                  fontSize: "14px",
                                  background: "radial-gradient(50% 388.06% at 50% 50%, #0054B5 0%, #01B7FF 100%)",
                                  textAlign: "center",
                                  cursor: "pointer"
                                }}
                              >
                                Choose Template
                              </div>
                            </div>
                          </div>
                        </div>
                      </Card>
                    ))}
                </div>
                {formData?.fields?.template?.touched &&
                  formData?.fields?.template?.hasError && (
                    <div className="error">
                      {formData?.fields?.template?.error}
                    </div>
                  )}
              </div>
            </div>
          </div>
          <CreateAppFooter
            nextStep={nextStep}
            step={step}
            prevStep={prevStep}
            price={'0'}
            type={0}
            setActiveTab={0}
          />
        </>
      )}
    </div>
  )
}

export default Step2
