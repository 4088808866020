import { InfoCircleOutlined } from '@ant-design/icons'

interface Props {
    title: string;
    price: number | string;
    type: boolean;
}

const SmallCardInfo = ({ title, price, type }: Props) => {

    return (
        <div className='smallCardInfoWrapper'>
            <div className='InnerCard'>
                <p className="card__title">{title}</p>
                {type && <InfoCircleOutlined onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />}
            </div>
            {price && <div className="card__content">${price}</div>}
        </div>
    )
}
export default SmallCardInfo;