import { HTMLAttributes, useContext } from 'react'
import { NavLink } from 'react-router-dom'
import jobsIcon from 'assets/jobs.svg'
import staffIcon from 'assets/staff.svg'
import templatesIcon from 'assets/templates.svg'
import subscriptionIcon from 'assets/subscriptionIcon.png'
import { AuthContext } from 'context/AuthContext'
import svgLogo from 'assets/Proton_site_logo.png';
import './navigation.sass'
import CreateAppIcon from 'components/SvgIcons/page/createApp'
import DashboardIcon from 'components/SvgIcons/page/dashboard'
import MyAppsIcon from 'components/SvgIcons/page/myapps'
import PaymentABillingIcon from 'components/SvgIcons/page/payment&billing'
import SubscriptionPlanIcon from 'components/SvgIcons/page/subscriptionplan'
import ProtonProIcon from 'components/SvgIcons/page/protonpro'
import LogoutIcon from 'components/SvgIcons/page/logout'
import HelpIcon from 'components/SvgIcons/page/help'
import { LINKS } from 'routes/constants'

interface IMenuItem {
  label: string
  route: string
  icon: any
  type?: string
}

const Admin_MENU: Array<IMenuItem> = [
  // {
  //   label: 'Dashboard',
  //   route: '/',
  //   icon: dashboardIcon,
  // },
  {
    label: 'Jobs',
    route: '/jobs',
    icon: jobsIcon,
  },
  {
    label: 'Staff',
    route: '/staff',
    icon: staffIcon,
  },
  {
    label: 'Customer',
    route: '/users',
    icon: staffIcon,
  },
  {
    label: 'Templates',
    route: '/templates',
    icon: templatesIcon,
  },
  // {
  //   label: 'Notifications',
  //   route: '/notifications',
  //   icon: notificationsIcon,
  // },
  {
    label: 'Subscription',
    route: '/subscription',
    icon: subscriptionIcon,
  },
]

const STAFF_MENU: Array<IMenuItem> = [
  // {
  //   label: 'Dashboard',
  //   route: '/',
  //   icon: dashboardIcon,
  // },
  {
    label: 'Jobs',
    route: '/jobs',
    icon: jobsIcon,
  },
  // {
  //   label: 'Notifications',
  //   route: '/notifications',
  //   icon: notificationsIcon,
  // },
]

const Customer_MENU: Array<IMenuItem> = [
  {
    label: 'Dashboard',
    route: LINKS.DASHBOARD,
    icon: DashboardIcon,
    type: 'svg'
  },
  {
    label: 'Create App',
    route: '/createApp',
    icon: MyAppsIcon,
    type: 'svg'
  },
  {
    label: 'My Apps',
    route: '/customer',
    icon: CreateAppIcon,
    type: 'svg'
  },
  {
    label: 'Payment & Billing',
    route: '/paymentsBilling',
    icon: PaymentABillingIcon,
    type: 'svg'
  },
  {
    label: 'Subscription Plan',
    route: '/subscription',
    icon: SubscriptionPlanIcon,
    type: 'svg'
  },
  {
    label: 'Proton Pro',
    route: '/protonpro',
    icon: ProtonProIcon,
    type: 'svg'
  },
  // {
  //   label: 'Pending tasks',
  //   route: '/pendingtask',
  //   icon: listIcon,
  // },
  // {
  //   label: 'Website',
  //   route: '/assets',
  //   icon: "/assets/list_1.png",
  // },
  // {
  //   label: 'Add new',
  //   route: '/customer/onboarding/',
  //   icon: "/assets/plus-icon.png",
  // },
  // {
  //   label: 'Payments',
  //   route: '/payments',
  //   icon: '/assets/credit-card.png',
  // },
  // {
  //   label: 'Notifications',
  //   route: '/Notifications',
  //   icon: bell,
  // },
  // {
  //   label: 'Inventory',
  //   route: '/inventory/addItem',
  //   icon: phoneIcon,
  // },
]

// const CUSTOMER_MENU: Array<IMenuItem> = []

interface INavigationProps extends HTMLAttributes<HTMLDivElement> { }

const Navigation = ({ ...props }: INavigationProps) => {
  const auth = useContext(AuthContext)
  const isCustomer = auth?.user?.permissions?.includes("customer");
  const isTemp = auth?.user?.permissions?.includes("temp");
  const isSuperAdmin = auth?.user?.permissions?.includes("administrator");
  return (
    <div className="navigation" {...props}>

      <div>
        <div className="sidebar-log">
          <img src={svgLogo} />
        </div>
        <div>
          {isCustomer || isTemp ? (
            <>
              {Customer_MENU.map((item) => (
                <NavLink
                  to={item.route}
                  key={item.label}
                  className={({ isActive }) => (isActive ? 'active' : '')}
                >
                  {item && item.type && item.type === 'svg' ? <item.icon /> : <img src={item.icon} alt={item.label} />}
                  <span className='nav-label'>{item.label}</span>
                </NavLink>
              ))}
            </>
          ) : isSuperAdmin ? (
            <>
              {Admin_MENU.map((item) => (
                <NavLink
                  to={item.route}
                  key={item.label}
                  className={({ isActive }) => (isActive ? 'active' : '')}
                >
                  {item && item.type && item.type === 'svg' ? <item.icon /> : <img src={item.icon} alt={item.label} />}
                  <span>{item.label}</span>
                </NavLink>
              ))}
            </>
          ) : (
            <>
              {STAFF_MENU.map((item) => (
                <NavLink
                  to={item.route}
                  key={item.label}
                  className={({ isActive }) => (isActive ? 'active' : '')}
                >
                  {item && item.type && item.type === 'svg' ? <item.icon /> : <img src={item.icon} alt={item.label} />}
                  <span>{item.label}</span>
                </NavLink>
              ))}
            </>
          )}
        </div>
      </div>
      <div className="navigation-bottom">
        <div className='navigation-bottom-items'>
          <HelpIcon />
          <span className='nav-label'>Help</span>
        </div>
        <div className='navigation-bottom-items logout'>
          <LogoutIcon />
          <span className='nav-label'>Logout</span>
        </div>
      </div>
    </div>
  );
}

export default Navigation
