import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// Define the type for each notification item
interface Notification {
  appid: string;
  image?: string;
  type: string;
  message: string;
  is_read: boolean;
}

// Define the state type, with notifications as an array of Notification objects
interface NotificationState {
  notifications: Notification[];
}

// Initial state with an empty array for notifications
const initialState: NotificationState = {
  notifications: [],
};

export const notificationSlice = createSlice({
  name: 'NOTIFICATIONSLICE',
  initialState,
  reducers: {
    // Reducer to set notifications
    setUserNotification: (state, action: PayloadAction<Notification[]>) => {
      // Ensure that the notifications are an array before setting
      if (Array.isArray(action.payload)) {
        state.notifications = action.payload;
      }
    },
    // Reducer to add a single notification immutably
    addNotification: (state, action: PayloadAction<Notification>) => {
      // Spread operator ensures immutability while adding a new notification
      state.notifications = [...state.notifications, action.payload];
    },
  },
});

// Export actions
export const { setUserNotification, addNotification } = notificationSlice.actions;

// Export the reducer
export default notificationSlice.reducer;
