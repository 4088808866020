import "./index.sass";

interface Props {
    status: "new" | "in_progress" | "completed" | "review" | "finished" | 'undefined';
}

const BadgeButton: React.FC<Props> = ({ status }) => {
    const NewStatus =
        status === "in_progress" ? "IN PROGRESS" :
            (!status || status === "new" || status === undefined) ? "NEW" :
                status.toUpperCase();

    return (
        <div className={`newBadge ${status}`}>
            <p className="status-dot"></p>
            <p style={{ whiteSpace: "nowrap" }}>{NewStatus}</p>
        </div>
    );
};

export default BadgeButton; 