import { Button } from "antd";
import NoJobData from 'components/NoJobData';
import CalenderIcon from "components/SvgIcons/calenderIcon";
import Notifactionicon from "components/SvgIcons/notifactioIcon";
import PlaceholderImage from "components/SvgIcons/placeholderImage";
import { NotiTypeEnum, formatTimestamp } from "pages/customer/CustomerPortal/utils";
import { useCallback, useState } from "react";
import { useDispatch } from 'react-redux';
import { useNavigate } from "react-router-dom";
import { setAppDemo, setAppLive, AppData } from 'store/slices/appDataSlice';

interface Props {
    data: any;
    handleUpdate: any;
    seNotifactionShow: any;
}

const NotiCard = ({ data, handleUpdate, seNotifactionShow }: Props) => {
    const [visibleCount, setVisibleCount] = useState(3);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handelClickAppLive = (id: any) => {
        const newAppData: AppData = {
            id: id,
            isOpen: true
        };
        dispatch(setAppLive(newAppData));
        seNotifactionShow(false);
        navigate('/customer');
    }

    const handelClickAppDemo = (id: any) => {
        const newAppData: AppData = {
            id: id,
            isOpen: true
        };
        dispatch(setAppDemo(newAppData));
        seNotifactionShow(false);
        navigate('/customer');
    }

    const handelClickAppTemplate = () => {
        console.log("App Template");
    }

    const handleShowMore = useCallback(() => {
        setVisibleCount((prevCount: any) => prevCount + 3); // Show more notifications
    }, []);


    const handleClick = (item: any) => {
        if (item?.type === NotiTypeEnum?.TEMPLATES) {
            handelClickAppTemplate();
        } else if (item?.job?.finalPaymentReceived) {
            handelClickAppLive(item?.job?.id);
        } else {
            handelClickAppDemo(item?.job?.id);
        }
    };

    return (
        <>
            {data.length > 0 ? (
                <>
                    {data.slice(0, visibleCount).map((item: any, index: number) => (
                        <div key={index} style={{ display: 'flex', gap: 16, padding: '20px 0', borderBottom: '1px solid #3A3F50' }}>
                            <div>
                                {item.image ? (
                                    <img
                                        width={"64px"}
                                        height={"70px"}
                                        style={{ borderRadius: '8px' }}
                                        src={item?.imageUrl ?? item?.image}
                                        alt={`Notification for ${item?.type}`}
                                    />
                                ) : (
                                    <PlaceholderImage style={{ borderRadius: '8px' }} width={"64px"} height={"64px"} />
                                )}
                            </div>
                            <div style={{ width: '100%', display: 'flex', flexDirection: 'column', gap: 15 }}>
                                <h2 onClick={() => handleUpdate(item?._id)}
                                    style={{
                                        fontFamily: "Mulish, sans-serif",
                                        fontWeight: 600,
                                        fontSize: "18px",
                                        lineHeight: "24px",
                                        color: "#FFFFFF",
                                        cursor: "pointer",
                                    }}>
                                    {item?.message}
                                </h2>
                                <div style={{ display: 'flex', gap: 12, alignItems: 'center' }}>
                                    <CalenderIcon />
                                    <p style={{ fontSize: '14px', color: '#FFFFFF' }}>
                                        {item?.createdAt && formatTimestamp(item?.createdAt)}
                                    </p>
                                    <p style={{ fontSize: '14px', color: '#FFFFFF' }}>|</p>
                                    <p style={{ fontSize: '14px', color: '#FFFFFF' }}>
                                        {item?.createdAt && formatTimestamp(item?.createdAt)}
                                    </p>
                                </div>
                                {item?.type !== NotiTypeEnum?.PAYMENT && (
                                    <div
                                        className="newLight active"
                                        onClick={() => handleClick(item)}
                                        style={{
                                            background: 'linear-gradient(90deg, #0054B5 0%, #01B7FF 100%)',
                                            color: '#fff',
                                            padding: '12px 0px',
                                            border: 'none',
                                            width: '100%',
                                            maxWidth: '414px',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            borderRadius: '8px',
                                            fontSize: '18px',
                                            fontWeight: '600'
                                        }}
                                    >
                                        {item?.type === NotiTypeEnum?.TEMPLATES
                                            ? 'Explore Template'
                                            : item?.job?.finalPaymentReceived
                                                ? 'App Live'
                                                : 'App Demo'}
                                    </div>
                                )}
                            </div>
                        </div>
                    ))}
                    {data.length > visibleCount && (
                        <Button className="newDark" style={{ width: '100%', maxWidth: '550px' }} onClick={handleShowMore}>
                            Show More
                        </Button>
                    )}
                </>
            ) : (
                <NoJobData
                    style={{ marginTop: '30px' }}
                    icon={<Notifactionicon height="60px" width="60px" />}
                    title="No Notifications to Show"
                />
            )}
        </>
    );
};

export default NotiCard;