import defaultAvatar from 'assets/avatar.png'
import { loadStripe } from '@stripe/stripe-js';

export const USER_ROLES = {
  'administrator': 'Administrator',
  'user': "User",
  // 'graphic-designer': 'Graphic designer',
  // 'backend-developer': 'Backend developer',
  // 'frontend-developer': 'Frontend developer',
  'customer': 'Customer',
  'temp': 'Temp',
}

// export const USER_ROLES = {
//   'super-admin': 'Administrator',
//   'graphic-designer': 'Graphic designer',
//   'backend-developer': 'Backend developer',
//   'frontend-developer': 'Frontend developer',
//   'customer': 'Customer',
//   'temp': 'Temp',
// }
export const TEMPLATES_TYPE = {
  'none': 'none',
  'create_job': 'Create Job',
  'forget_password': 'Forgot Password',
  'invite_staff': 'Invite Staff',
  'staff_access': 'Staff Access',
  // 'onboard_customer': 'Onboard Customer',
  'customer_access': 'Customer Access',
  "existing_customer_access": "Existing Customer Access",
  "building_job": " Building Job",
  "payment_received": "Payment Received",
  "active_job": "Active Job"
}

export const TEMPLATES_VARIABLE = [
  {
    type: "create_job",
    variables: {
      'customer_name': '[Customer Name]',
      'link': '[Link]',
      'logo': '[logo]'
    }
  },
  {
    type: "forget_password",
    variables: {
      'link': '[Link]',
      'logo': '[logo]'
    }
  },
  {
    type: "invite_staff",
    variables: {
      'full_name': '[Full Name]',
      'role': '[Role]',
      'link': '[Link]',
      'logo': '[logo]'
    }
  },
  {
    type: "staff_access",
    variables: {
      'full_name': '[Full Name]',
      'link': '[Link]',
      'email': '[Email]',
      'password': '[Password]',
      'logo': '[logo]'
    }
  },
  {
    type: "customer_access",
    variables: {
      'customer_name': '[Customer Name]',
      'link': '[Link]',
      'email': '[Email]',
      'password': '[Password]',
      'logo': '[logo]'
    }
  },
  {
    type: "existing_customer_access",
    variables: {
      'customer_name': '[Customer Name]',
      'link': '[Link]',
      'email': '[Email]',
      'logo': '[logo]'
    }
  },
  {
    type: "building_job",
    variables: {
      'customer_name': '[Customer Name]',
      // 'job_name': '[Job Name]',
      'logo': '[logo]'
    }
  },
  {
    type: "payment_received",
    variables: {
      'customer_name': '[Customer Name]',
      'payment': '[Payment]',
      'paymentType': '[Payment Type]',
      'logo': '[logo]'
      // 'job_name': '[Job Name]',
    }
  },
  {
    type: "active_job",
    variables: {
      'customer_name': '[Customer Name]',
      'logo': '[logo]'
      // 'job_name': '[Job Name]',
    }
  }
]

export const PriceApiURl = "https://cdn.jsdelivr.net/npm/@fawazahmed0/currency-api@latest/v1/currencies/";

export const google_client_id =
  '1036047362354-34r3m3o9n140sjs3vmgcqbult1fa5429.apps.googleusercontent.com'
export const crypto_secret = 'SVXFjCfIM2iScbGyIMG3clTGIb8zaymu'

export const JobSummery_type = {
  type: {
    new: 'NEW',
    in_progress: 'IN PROGRESS',
    review: 'REVIEW',
    promoted: 'PROMOTED',
    finished: 'FINISHED',
  },
}

export const SERVICES = {
  APP_TEMPLATES: 'app-templates',
  EMAIL_TEMPLATES: 'email-templates',
  TASK_TEMPLATES: 'task-templates',
  JOBS: 'jobs',
  EMAILS: 'emails',
  SENDEMAIL: 'sendEmail',
  ACTIVITY: 'activity',
  USERS: 'users',
  TASK: 'tasks',
  INVENTORY: 'inventory',
  SUBSCRIPTION: 'subscription',
  NOTIFICATIONS: 'notifications'
}
/** live  Start*/
export const URL_PATH = {
  CLIENT: 'https://api.postal.com.au',
  FILE: 'https://files.postal.com.au/',
  CLIENT1: 'https://pro.postal.com.au:3000/',
  FILE1: 'https://pro.postal.com.au:3001/',
}
export const stripePromise = loadStripe('pk_live_51P6o5wAON2BF3DZ1x5Z210X6RsekNFDLOvwWLddxy4Yo5304w2fQ0ilyefSNWoUf8NYazqrZQjqDrLXJ1AjNJu6W00pNhHUXxa');

/** live  End*/

/** Local Start */
// export const URL_PATH = {
//    CLIENT: 'https://api.postal.com.au',
//    FILE: 'https://files.postal.com.au/',
//    CLIENT1: 'http://localhost:3030/',
//    FILE1: 'http://localhost:3031/',
//  }
//  export const stripePromise = loadStripe('pk_test_51P6o5wAON2BF3DZ1LDszecnulsd2DSXVMzToOSj5wW4pS3CGjMgWd2wATOAB0RChyI3L0mdjC1EGZbWmuUvwoevS0054jaobzs');

/** Local End */

export function generateRandomPassword(length: number): string {
  const charset: string = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*()_-+=";
  let password: string = "";

  for (let i: number = 0; i < length; i++) {
    const randomIndex: number = Math.floor(Math.random() * charset.length);
    password += charset[randomIndex];
  }

  return password;
}
export const DEFAULT_AVATAR = defaultAvatar

export const Priority = [
  {
    status: 'low',
    backgroundColor: 'blue',
  },
  {
    status: 'lowest',
    backgroundColor: 'green',
  },
  {
    status: 'high',
    backgroundColor: '#00B8D9',
  },
  {
    status: 'highest',
    backgroundColor: 'red',
  },
]

export const taskStatus = [
  {
    value: 'review',
    label: 'Review',
  },
  {
    value: 'promote',
    label: 'Promote',
  },
  {
    value: 'progress',
    label: 'In Progress',
  },
  {
    value: 'finished',
    label: 'Finished',
  },
]

export const estimated_time = [
  {
    value: '1h 30m',
    label: '1h 30m',
  },
  {
    value: '2h',
    label: '2h',
  },
  {
    value: '2h 30min',
    label: '2h 30min',
  },
]

export const logged_time = [
  {
    value: '1h 30m',
    label: '1h 30m',
  },
  {
    value: '2h',
    label: '2h',
  },
  {
    value: '2h 30min',
    label: '2h 30min',
  },
]
