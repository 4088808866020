interface Props {
  width?: string | number;
  height?: string | number;
}

const LogoutIcon = ({ height = "22px", width = "22px" }: Props) => {
  return (
    <svg width="22" height="23" viewBox="0 0 22 23" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M8.25 19.75H4.58333C4.0971 19.75 3.63079 19.5568 3.28697 19.213C2.94315 18.8692 2.75 18.4029 2.75 17.9167V5.08333C2.75 4.5971 2.94315 4.13079 3.28697 3.78697C3.63079 3.44315 4.0971 3.25 4.58333 3.25H8.25" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M14.6667 16.0832L19.25 11.4998L14.6667 6.9165" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M19.25 11.5H8.25" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  )
}
export default LogoutIcon;