import React from "react";

const RoundRdioSvg: React.FC = () => {
    return (
        <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="11" cy="11" r="10.5" stroke="currentColor" />
        </svg>
    )
}
export default RoundRdioSvg;

