import { useState, useContext, useEffect } from 'react'
import "pages/customer/payments/index.sass"
import "pages/customer/payments/form.sass"
import { URL_PATH } from 'utils/constants'
import "pages/customer/customer.sass"
import { AuthContext } from 'context/AuthContext'
import axios from 'axios'
import { getImageForBrand, getExpiryStatus } from 'utils/helpers'
import 'react-toastify/dist/ReactToastify.css';
import { SavedCard } from 'types/card'
import Card from 'pages/customer/payments/Card';
import Button from 'components/forms/Button/Button';

interface props {
    setActiveTab: any;
    setSelectedPaymentCard?: any;
}

const PaymentsCard = ({ setActiveTab, setSelectedPaymentCard }: props) => {
    const [loading, setLoading] = useState(false);
    const [savedCards, setSavedCards] = useState<SavedCard[]>([]);
    const [selectedCard, setSelectedCard] = useState('');

    const auth = useContext(AuthContext);

    const handleTabClick = (index: number) => {
        setActiveTab(index);
    };

    const fetchPaymentMethods = async () => {
        try {
            setLoading(true)
            const config = {
                method: 'post',
                maxBodyLength: Infinity,
                url: `${URL_PATH.CLIENT1}get-payment-methods`,
                headers: {
                    'Content-Type': 'application/json',
                },
                data: JSON.stringify({
                    user_Id: auth?.user?._id,
                    name: auth?.user?.name,
                    email: auth?.user?.email
                }),
            }
            const response = await axios.request(config);
            if (response && (response.status === 200 || response.status === 201)) {
                setSavedCards(response?.data?.data);
                setSelectedPaymentCard(response?.data?.data[0] || [])
            }
        } catch (error) {
            console.error('Error fetching payment methods:', error);
        } finally {
            setLoading(false);
        }
    };
    useEffect(() => {
        if (auth?.user?._id) {
            fetchPaymentMethods();
        }
    }, [auth]);

    return (
        <div style={{ height: '97%', background: "#00020333", padding: "1px 15px" }}>
            {loading && (
                <div className="loader"></div>
            )}
            <section className='paymentMain'>
                <div>
                    <div className='PymentInfoDiv'>
                        <p className='paymentInfoTitle'>Saved Cards</p>
                        {!savedCards?.length && (
                            <p className='paymentInfopara'>You haven't nominated a payment method</p>
                        )}
                    </div>
                </div>
            </section>

            <div className="payments-right-side" style={{ height: "calc(100% - 65px)", position: "relative" }}>
                <div className="cards-list">
                    {savedCards?.length ? savedCards.map((card, index) => (
                        // card
                        <Card
                            key={index}
                            title={card.card.brand === 'paypal' ? card.card.brand : `Ending in ${card.card.last4}`}
                            expiryDate={card.card.exp_month + '/' + card.card.exp_year}
                            image={getImageForBrand(card.card.brand)}
                            showStatus={getExpiryStatus(card.card.exp_month, card.card.exp_year)}
                            paymentMethodId={card.id}
                            fetchPaymentMethods={fetchPaymentMethods}
                            onClick={() => setSelectedCard(card.id)}
                            checked={selectedCard === card.id}  // Ensures only one card is selected
                            />
                    )) :
                        <p></p>
                    }
                </div>
                <Button style={{ background: "#0077FF", fontWeight: 0, position: "absolute", bottom: "2rem", right: 0 }} onClick={(e) => handleTabClick(3)}>Add New Card</Button>
            </div>
        </div>
    )
}

export default PaymentsCard;