interface Props {
  width?: string | number;
  height?: string | number;
}

const HelpIcon = ({ height = "22px", width = "22px" }: Props) => {
  return (
    <svg width="22" height="23" viewBox="0 0 22 23" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M11 20.6668C16.0626 20.6668 20.1666 16.5628 20.1666 11.5002C20.1666 6.43755 16.0626 2.3335 11 2.3335C5.93737 2.3335 1.83331 6.43755 1.83331 11.5002C1.83331 16.5628 5.93737 20.6668 11 20.6668Z" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M8.33252 8.75003C8.54803 8.1374 8.97341 7.6208 9.53331 7.29174C10.0932 6.96268 10.7515 6.84239 11.3916 6.95219C12.0317 7.06198 12.6123 7.39476 13.0305 7.8916C13.4487 8.38844 13.6777 9.01726 13.6767 9.6667C13.6767 11.5 10.9267 12.4167 10.9267 12.4167" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M11 16.0835H11.0092" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  )
}
export default HelpIcon;