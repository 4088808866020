import { HTMLAttributes, useContext } from 'react'
import Navigation from './Navigation/Navigation'
import './sidebar.sass'
import { AuthContext } from 'context/AuthContext'
import LogoutIcon from 'components/SvgIcons/page/logout'
import HelpIcon from 'components/SvgIcons/page/help'
interface ISidebarProps extends HTMLAttributes<HTMLDivElement> { }

const Sidebar = ({ ...props }: ISidebarProps) => {
  const auth = useContext(AuthContext)

  return (
    <div className="sidebar" {...props}>
      <div className="top">
        <Navigation />
      </div>
    </div>
  )
}

export default Sidebar
