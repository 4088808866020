import React, { useEffect, useState } from 'react';
import CreateAppFooter from '../../footer';
import FromHeader from '../formHeader';
import { onFocusOut, onInputChange } from '../../../../../../components/lib/validation/createApp';
import { handleFileUpload } from 'pages/customer/CustomerPortal/utils';
import { toast } from 'react-toastify';
interface Step1Props {
    formData: any;
    nextStep: () => void;
    prevStep: () => void;
    step: number;
    name: string;
    dispatchForm: any;
    preview: any;
    setPreview: any;
}
const Step3 = ({ formData, dispatchForm, nextStep, step, name, setPreview, prevStep, preview }: Step1Props) => {
    const [file, setFile] = useState<string | null>(null);
    // Handle image removal
    const handleRemoveImage = () => {
        setFile(null);
        setPreview(null);
        onInputChange("attachment", '', dispatchForm, formData);
    };
    const LabelStyle = {
        color: '#EDEDED',
        fontFamily: "'Mulish', sans-serif",
        fontSize: '18px',
        fontWeight: '600',
        lineHeight: '31.63px',
        letterSpacing:'0px',
        padding:'0px',
        marginBottom:'12px',
        width:'100%',
        display:'block'
    }
    const handleChangeFile = async (e: React.ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files?.[0]; // Get the first file
        if (file) {
            try {
                // Ensure that the fileupload is awaited and properly assigned
                const fileupload: any = await handleFileUpload(file);
                if (fileupload) {
                    onInputChange("attachment", fileupload, dispatchForm, formData);
                    setPreview(fileupload);
                    const fileUrl = URL.createObjectURL(file);
                    setFile(fileUrl);
                }
            } catch (error) {
                toast.error(`Error during file upload. ${error}`);
            }
        }
    };
    return (
        <div style={{ width: '100%' }}>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column'
                }}
            >
                <div className="main_card" style={{
                    width: '100%'
                }}>
                    <FromHeader
                        title={"Customization Details"}
                        desc={"Provide the details to personalize your app according to your business need."}
                        step={step}
                        name={name}
                    />
                    <div className="main_section_card">
                        <div className='formWrapperCustomization'>
                            <div className='formRows'>
                                <div className='formsCol'>
                                    <label style={LabelStyle} >
                                        Write the display name of your app
                                    </label>
                                    <input
                                        name="displayName"
                                        value={formData.fields.displayName?.value}
                                        className={`website-input ${formData.fields.displayName.touched && formData.fields.displayName.hasError ? "error" : ""}`}
                                        onChange={e => {
                                            onInputChange("displayName", e.target.value, dispatchForm, formData);
                                        }}
                                        onBlur={e => {
                                            onFocusOut("displayName", e.target.value, dispatchForm, formData);
                                        }}
                                        placeholder='Enter your app name'/>
                                    {formData?.displayName?.touched && formData?.displayName?.hasError && (
                                        <div className="error">{formData?.displayName?.error}</div>
                                    )}
                                </div>
                                <div className='formsCol'>
                                    <label
                                        style={LabelStyle}
                                    >
                                        Business Website URL (Optional)
                                    </label>
                                    <input
                                        name="website"
                                        value={formData.fields.website.value}
                                        className={`website-input ${formData.fields.website.touched && formData.fields.website.hasError ? "error" : ""}`}
                                        onChange={e => {
                                            onInputChange("website", e.target.value, dispatchForm, formData);
                                        }}
                                        onBlur={e => {
                                            onFocusOut("website", e.target.value, dispatchForm, formData);
                                        }}
                                        placeholder='https//:www.abc.com'
                                        style={{
                                            padding: '10px',
                                            borderRadius: '4px',
                                            border: '1px solid #ccc',
                                            fontSize: '14px',
                                            background: 'transparent'
                                        }}
                                    />
                                    {formData?.website?.touched && formData?.website?.hasError && (
                                        <div className="error">{formData?.website?.error}</div>
                                    )}
                                </div>
                            </div>
                            <div className='formRows'>
                                <div className='formsCol'>
                                <label
                                    style={LabelStyle}
                                >
                                    Would you like to inform our team of anything? (Optional)
                                </label>
                                {/* Textarea input */}
                                <textarea
                                    name="message"
                                    value={formData.fields.message.value}
                                    placeholder='Your message...'
                                    className={`website-input ${formData.fields.message.touched && formData.fields.message.hasError ? "error" : ""}`}
                                    onChange={e => {
                                        onInputChange("message", e.target.value, dispatchForm, formData);
                                    }}
                                    onBlur={e => {
                                        onFocusOut("message", e.target.value, dispatchForm, formData);
                                    }}
                                    rows={4}
                                    style={{
                                        width: '100%',
                                        padding: '10px',
                                        borderRadius: '4px',
                                        border: '1px solid #ccc',
                                        resize: 'vertical',
                                        fontSize: '14px',
                                        background: 'transparent'
                                    }}
                                />
                                {formData?.message?.touched && formData?.message?.hasError && (
                                    <div className="error">{formData?.message?.error}</div>
                                )}
                                </div>
                            </div>
                            <div className='formRows'
                                style={{
                                    width: '100%',
                                    display: 'flex',
                                    flexDirection: 'column',
                                }} >
                                <div className='formsCol' style={{
                                    width: '100%',
                                }}>
                                <label
                                    style={LabelStyle}
                                >
                                    File Attachment
                                </label>
                                <div className='inputAttaced'>
                                  <div className='browserButton'>
                                    <input
                                            name="attachment"
                                            type='file'
                                            className={`website-input ${formData.fields.attachment.touched && formData.fields.attachment.hasError ? "error" : ""}`}
                                            onChange={e => {
                                                handleChangeFile(e);
                                            }}
                                            style={{
                                                width: '100%',
                                                display: 'flex',
                                                flexDirection: 'row',
                                                flexWrap: 'wrap',
                                                borderRadius: '4px',
                                                background: 'transparent',
                                                minHeight:'50px',
                                            }}
                                        />
                                      Browse
                                  </div>
                                </div>
                                </div>
                                {formData?.attachment?.touched && formData?.attachment?.hasError && (
                                    <div className="error">{formData?.attachment?.error}</div>
                                )}
                                {file && (
                                    <div style={{
                                        position: 'relative',
                                        marginTop: '20px',
                                        border: '1px solid #ccc',
                                        padding: '10px',
                                        width: '125px',
                                    }}>
                                        <img src={file} alt="Preview" style={{ width: '100px', height: '100px', objectFit: 'contain' }} />
                                        <a type="button" onClick={handleRemoveImage} 
                                            style={{
                                                position: "absolute",
                                                right: "-15px",
                                                top: "-10px",
                                                fontSize: "20px",
                                                fontWeight: "600",
                                                border: "1px solid rgb(204, 204, 204)",
                                                borderRadius: "50%",
                                                width: "25px",
                                                height: "25px",
                                                textAlign: "center",
                                                lineHeight: "20px",
                                                color: "rgb(0, 0, 0)",
                                                background: "rgb(255, 255, 255)",
                                                cursor: "pointer"
                                            }}>
                                            x
                                        </a>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <CreateAppFooter nextStep={nextStep} step={step} prevStep={prevStep} price={"0"} type={0} setActiveTab={0} />
        </div >
    );
};
export default Step3;
function dispatch(arg0: any) {
    throw new Error('Function not implemented.');
}
