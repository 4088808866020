import UparrowIcon from "components/SvgIcons/uparrowIcone";
import { useCallback, useEffect, useMemo, useState } from "react";
import NotiCard from "./card";
import { useDispatch, useSelector } from "react-redux";
import { setUserNotification } from "store/slices/notifactionSlice";
import { fetchNotifications, getAppByJobId, getUrlFileURL, markAllAsRead, updateNotification } from "pages/customer/CustomerPortal/utils";
import { toast } from "react-toastify";
import { RootState } from 'store/store';

interface Props {
    notifactionShow: any;
    seNotifactionShow?: any;
}

const NotifactionCard = ({ notifactionShow, seNotifactionShow }: Props) => {
    const dispatch = useDispatch();
    const [activeTab, setActiveTab] = useState(0);
    const [unreadCount, setUnreadCount] = useState(0);
    const notifications = useSelector((state: RootState) => state.notifactionReducer.notifications);
    const [notificationsWithUrls, setNotificationsWithUrls] = useState<any[]>([]);

    const handelMarkRead = async () => {
        try {
            await markAllAsRead();
            toast.success('All notifications marked as read successfully');
            fetchNotifications(dispatch, setUserNotification);
        } catch (error: any) {
            toast.error('Failed to update notification: ' + error?.message);
        }
    }

    const handleUpdate = useCallback(async (notificationId: string) => {
        if (notificationId) {
            try {
                const updateData = { is_read: true };
                await updateNotification(notificationId, updateData);
                toast.success('Notification updated successfully');
                fetchNotifications(dispatch, setUserNotification);
            } catch (error: any) {
                toast.error('Failed to update notification: ' + error?.message);
            }
        }
    }, []);

    const handleTabClick = (index: number) => {
        setActiveTab(index);
    };

    const tabs = ["All", "Read", "Unread"];

    useEffect(() => {
        if (notifactionShow) {
            fetchNotifications(dispatch, setUserNotification);
        }
    }, [notifactionShow]); // Run once when the component mounts

    const fetchJobData = async (notifications: any) => {
        const notificationsData = await Promise.all(
            notifications.map(async (notification: any) => {
                if (notification && notification.appid) {
                    const job = await getAppByJobId(notification.appid);
                    const imageUrl = await getUrlFileURL(notification.image);
                    return { ...notification, job, imageUrl };
                }
                return notification; // Return the original notification if no appid
            })
        );
        return notificationsData;
    };

    // const memoizedNotifications = useMemo(() => {
    //     return notifications.map(notification => notification.appid).join(',');
    // }, [notifications]);

    // useEffect(() => {
    //     const fetchData = async () => {
    //         const notificationsData = await fetchJobData(notifications);
    //         setNotificationsWithUrls(notificationsData);
    //     };

    //     if (notifications.length > 0) {
    //         fetchData();
    //     }
    // }, [memoizedNotifications]); // Depend on the memoized value
    useEffect(() => {
        const fetchData = async () => {
            if (notifications.length > 0) {
                const notificationsData = await fetchJobData(notifications);
                setNotificationsWithUrls(notificationsData);
            }
        };
    
        fetchData();
    }, [notifications]); // Directly depend on notifications
    

    useEffect(() => {
        // Calculate unread notifications count when `notifications` change
        const unreadNotifications = notifications.filter((notification: any) => notification.is_read == 0);
        setUnreadCount(unreadNotifications.length);
    }, [notifications]); // Dependency on `notifications` so it updates when they change

    const filteredNotifications = (filter: string) => {
        if (filter === "read") {
            return notificationsWithUrls.filter((notification: any) => notification.is_read == 1);
        }
        if (filter === "unread") {
            return notificationsWithUrls.filter((notification: any) => notification.is_read == 0);
        }
        return notificationsWithUrls;
    };

    return (
        notifactionShow &&
        <div className=""
            style={{
                position: notifactionShow ? 'absolute' : 'relative',
                width: '100%',
                height: '650px',
                maxWidth: '550px',
                overflow: 'auto',
                right: '20px',
                top: '68px',
                borderRadius: '8px',
                padding: '24px',
                background: "#000B14"
            }}
        >
            <div style={{
                position: 'relative',
                width: '100%'
            }}>
                <UparrowIcon />
                <div className="header" style={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    margin: '15px 0'
                }}>
                    <h4 style={{
                        fontSize: '30px',
                        fontWeight: 'medium'
                    }}>Notification</h4>
                    {!(unreadCount && unreadCount > 0) ? <a
                        style={{
                            color: ' #929292',
                            fontSize: '20px',
                            textDecoration: 'underline',
                        }}
                        href="#"
                        onClick={() => handelMarkRead()}
                    >Mark as read</a>
                        : ''
                    }
                </div>
                <div className="tab-container" style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 14
                }}>
                    <div className="tab-group"
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            borderRadius: '15px', 
                            overflow: "hidden",  
                            border: "1px solid #1880D6", 
                        }}
                    >
                        {tabs && tabs.map((tab: any, index: any) => (
                            <div
                                key={index}
                                className={`tab ${activeTab === index ? 'active' : ''}`}
                                onClick={() => handleTabClick(index)}
                                aria-selected={activeTab === index}
                                style={{
                                    background: activeTab === index ? '#222647' : '#fff',
                                    color: activeTab === index ? '#fff' : '#222647',
                                    padding: '10px 0px',
                                    width: '100%',
                                    maxWidth: '33.33%',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    borderRadius: index === 0 ? '12px 0 0 12px'
                                        : index === tabs.length - 1 ? '0 12px 12px 0'
                                            : '0',  // Ensure rounded corners on the first and last tab
                                    border: 'none', // Remove individual tab borders
                                }}
                            >
                                <span className="tab-label" style={{
                                    fontSize: '20px',
                                    fontWeight: '500'
                                }}>{tab}</span>
                            </div>
                        ))}
                    </div>


                    <div className="tab-content" style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 12,
                        minHeight: "200px",
                    }}>
                        {notifications.length > 0 && (
                            <>
                                {activeTab === 0 && <NotiCard data={filteredNotifications("all")} handleUpdate={handleUpdate} seNotifactionShow={seNotifactionShow} />}
                                {activeTab === 1 && <NotiCard data={filteredNotifications("read")} handleUpdate={handleUpdate} seNotifactionShow={seNotifactionShow} />}
                                {activeTab === 2 && <NotiCard data={filteredNotifications("unread")} handleUpdate={handleUpdate} seNotifactionShow={seNotifactionShow} />}
                            </>
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default NotifactionCard;