import React, { useState } from 'react';
import LightOutLined from "components/SvgIcons/lightOutLined";
import MoonIcon from "components/SvgIcons/moonIcon";

const AppMode: React.FC = () => {
    const [activeMode, setActiveMode] = useState<string>('light'); // Track the active mode

    const handleClick = (mode: string) => {
        setActiveMode(mode);
    };

    return (
        <div className='modeFilter'>
            <p className='paraFilter'>App Mode</p>
            <div className='filterModeButton'>
                <button 
                    className={activeMode === 'light' ? 'active' : ''} 
                    onClick={() => handleClick('light')} 
                >
                    <LightOutLined /> Light Mode
                </button>
                <button 
                    className={activeMode === 'dark' ? 'active' : ''} 
                    onClick={() => handleClick('dark')} 
                >
                    <MoonIcon /> Dark Mode
                </button>
            </div>
        </div>
    );
};

export default AppMode;
