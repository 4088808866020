import { HTMLAttributes, useCallback, useRef, useState } from 'react'

import { useOutsideAlerter } from 'utils/hooks'

import Button from 'components/forms/Button/Button'

import kebabIcon from 'assets/kebab.svg'

import './kebab-menu.sass'
import arrowsdrop from 'assets/drop.png';
import MenuDotsIcone from 'components/SvgIcons/menuDots'

export interface IKebabMenuItem extends HTMLAttributes<HTMLLIElement> {
  label: string
  icon: string
  onClick: () => void,
  style?: any
}
interface IKebabMenuProps extends HTMLAttributes<HTMLDivElement> {
  items: IKebabMenuItem[]
  isOpen?: boolean,
  disabled?: boolean
}

const KebabMenu = ({ items, isOpen: _isOpen, disabled = false, ...props }: IKebabMenuProps) => {
  const [isOpen, toggleIsOpen] = useState(_isOpen || false)

  const handleButtonClick = useCallback(
    (e: any) => {
      toggleIsOpen(!isOpen)
    },
    [isOpen, toggleIsOpen]
  )

  const menuRef = useRef(null)
  useOutsideAlerter(menuRef, () => {
    setTimeout(() => {
      toggleIsOpen(false)
    }, 100)
  })

  return (
    <div {...props} className="kebab-menu">
      <Button disabled={disabled} theme="transparent" onClick={handleButtonClick}>
        <MenuDotsIcone width={"24px"} height={"24px"} color={"#EDEDED"} />
      </Button>
      {isOpen && (
        <ul
          className="kebab-menu-list"
          ref={menuRef}
          onClick={() => {
            toggleIsOpen(false)
          }}
        >
          <div className='dropdownTri'>
            {/* <span className='dropAngl'></span> */}
            <img src={arrowsdrop} alt="arrows" className='arrowstep' />
          </div>
          {items.map(({ label, icon, ...props }) => (
            <li {...props} key={label}>
              <img src={icon} alt={label} />
              <span>{label}</span>
            </li>
          ))}
        </ul>
      )}
    </div>
  )
}

export default KebabMenu
