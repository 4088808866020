import PatmentTabCard from 'components/common/paymentTabCard';

interface Step1Props {
    formData: any;
    submitForm: () => void;
    prevStep: () => void;
    step: number;
    name: string;
    activeTab: any;
    setActiveTab: any;
    type?: number;
    patymentDone?: boolean;
    setSelectedPaymentCard?: any;
    dispatchForm: any;
    preview?:any;
}

const Step = ({ formData, setActiveTab, activeTab, submitForm, prevStep, step, name, patymentDone, setSelectedPaymentCard,preview }: Step1Props) => {
    const tabs = ["Order Summary", "First Payment", "Payment Status"];
    const paymentType = "deposit";
    const title = "Deposit received!";
    const desc = "Your deposit has been successfully processed. You can now proceed with the next steps to bring your app to life.."
    const descPop = ["Review the details of your order, before proceeding to checkout.","Complete your first payment securely to unlock access and start building with confidence.","Track the status of your payment and stay informed about your transaction progress."];

    return (
        <PatmentTabCard formData={formData} setActiveTab={setActiveTab} activeTab={activeTab} submitForm={submitForm} prevStep={prevStep} step={step} name={name} patymentDone={patymentDone} setSelectedPaymentCard={setSelectedPaymentCard} tabs={tabs} paymentType={paymentType} title={title} desc={desc} descPop={descPop} preview={preview} />
    );
};

export default Step;
